import { Action } from '@hum/icm-app/src/actions';
import { AppState } from '@hum/icm-app/src/state';
import { produce } from 'immer';
import { CreditBoxInvestorsActionType } from '../actions';

export const creditBoxReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_STATE_CHANGED: {
      return produce(state, (newState) => {
        Object.assign(newState.creditBoxReport, action.payload);
      });
    }

    case CreditBoxInvestorsActionType.CREDIT_BOX_REPORT_REFRESHED_STATE_CHANGED: {
      const currentReports = state.creditBoxReport.data || [];
      const newReports = action.payload.data || [];
      const refreshedReportIndex = currentReports?.findIndex((report) =>
        newReports?.find(
          (updatedReport) =>
            updatedReport.companyId === report.companyId &&
            updatedReport.type === report.type
        )
      );

      if (refreshedReportIndex > -1) {
        const newReport = newReports[0];
        const currentReportsCopy = [...currentReports];
        currentReportsCopy.splice(refreshedReportIndex, 1, newReport);
        return produce(state, (newState) => {
          newState.creditBoxReport = {
            data: currentReportsCopy,
            loaded: true,
          };
        });
      } else {
        return produce(state, (newState) => {
          newState.creditBoxReport = action.payload;
        });
      }
    }

    case CreditBoxInvestorsActionType.IS_DOWNLOADING_CREDIT_BOX_REPORT: {
      return produce(state, (newState) => {
        newState.isDownloadingCreditBoxReport = true;
      });
    }

    default:
      return state;
  }
};
