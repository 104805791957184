import React from 'react';
import { env } from '@hum/common';
import { TextInputField, InputMode, CheckboxField } from '@hum/legacy-ui';
import { Button, ButtonSize } from '@hum/ui-library';
import { useAnalyticsEvent } from '@hum/api';

const SVB_NAMES = ['SVB Pilot']; // if investor name matches this, it's under SVB pilot

type InvestorSetPasswordFormProps = {
  companyName: string;
  form: any;
  fields: any;
  tosIsAccepted: boolean;
  reviewedCommunicationsOptIn: boolean;
};

export const InvestorSetPasswordForm = ({
  form,
  companyName,
  fields,
  tosIsAccepted,
  reviewedCommunicationsOptIn,
}: InvestorSetPasswordFormProps) => {
  const trackEvent = useAnalyticsEvent();

  const notSVBPilot = !SVB_NAMES.includes(companyName);

  return (
    <form onSubmit={form.handleSubmit}>
      <TextInputField
        spaceBottom
        label="Your Password"
        infoText="10 character minimum"
        {...fields.password.input}
        inputMode={InputMode.PASSWORD}
        inputTestId="set-password:password"
      />
      {!reviewedCommunicationsOptIn && (
        <CheckboxField
          {...fields.acceptedCommunications}
          className="signUpToggle boxOnTop"
          boxOnTop
          data-testid="company_signup:accept_communications_opt_in_checkbox"
          label={'I agree to receive communications from Hum Capital'}
        />
      )}
      {notSVBPilot && !tosIsAccepted && (
        <CheckboxField
          spaceBottom
          data-testid="portfolio-investor:legal-accepted-checkbox"
          {...fields.acceptedTOS}
          label={
            <>
              I agree to Hum Capital's{' '}
              <a
                data-id="required_legal_documents:tos_to_learn_more"
                href={`https://${env.CAPITAL_MAIN_DOMAIN}/terms-of-service`}
                data-testid="syndication:legal-agreements-modal-show-agreement-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Investor Terms of service
              </a>
            </>
          }
        />
      )}
      <Button
        size={ButtonSize.LG}
        fullWidth
        submit
        testId="syndication_reset_password:button:update_password"
        loading={form.isSubmitting}
        disabled={notSVBPilot && !tosIsAccepted && !fields.acceptedTOS.value}
        onClick={() => {
          trackEvent('investor:syndication:terms_of_service:signed', {}, false);
        }}
      >
        Continue
      </Button>
    </form>
  );
};
