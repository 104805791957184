import React, { useState, useEffect } from 'react';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { CompanyCharTabName } from '@hum/icm-app/src/state';
import { Company, CompanyType } from '@hum/types';
import { Spinner } from '@hum/ui-library';
import { Pill, PageTitle } from '@hum/legacy-ui';
import { useCharts } from '@hum/icm-app/src/hooks/useCharts';
import * as styles from './index.pc';
import { analytics } from '@hum/common';

import { ChartList } from '@hum/icm-app/src/components/ChartList';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getChartsByTabName, useApiQuery } from '@hum/api';

const TAB_NAMES = {
  [CompanyCharTabName.preTermSheet]: 'Pre term sheet',
  [CompanyCharTabName.diligence]: 'Diligence',
  [CompanyCharTabName.servicing]: 'Servicing',
  [CompanyCharTabName.historicalData]: 'Revenue Analytics',
  [CompanyCharTabName.projection]: 'Financing Terms',
  [CompanyCharTabName.syndication]: 'Syndication (post-intro)',
  [CompanyCharTabName.portfolioMonitoring]: 'Portfolio Monitoring',
  [CompanyCharTabName.syndicationPreIntro]: 'Syndication (pre-intro)',
  [CompanyCharTabName.portfolioAnalytics]: 'PA Analytics',
};

export const AnalyticsTab: React.FC<{
  activateTab: (params?: {}) => void;
}> = ({ activateTab }) => {
  const [visible, setVisible] = useState(false);

  const onMenuItemClick = async (value: string) => {
    setVisible(!visible);
    activateTab({ chart: value });
  };

  // hack for closing menu when body is clicked
  useEffect(() => {
    if (!visible) {
      return;
    }

    const onClick = () => {
      // need to do quick timeout in case menu item is clicked
      setTimeout(setVisible, 100, false);
    };
    document.body.addEventListener('click', onClick, true);

    return () => document.body.removeEventListener('click', onClick, true);
  }, [visible]);

  const onClick = (event: React.MouseEvent<any>) => {
    // stop propagation to prevent tab parent from activating this tab
    event.stopPropagation();
    setVisible(!visible);
  };

  const { state } = useAppStore();
  const isAdmin = state.session.data?.roles.includes(CompanyType.Admin);
  if (isAdmin) {
    TAB_NAMES[CompanyCharTabName.audit] = 'Audit';
  }

  return (
    <styles.AnalyticsTabLabel
      onClick={onClick}
      visible={visible}
      menuItems={
        visible &&
        Object.keys(TAB_NAMES).map((tabName) => (
          <styles.MenuItem
            key={tabName}
            onClick={(event: React.MouseEvent<any>) => {
              // stop propagation to prevent tab parent from activating this tab
              event.stopPropagation();

              onMenuItemClick(tabName);
              // @ts-ignore
              analytics.track(`analytics:admin_change_tab`, tabName);
            }}
          >
            {TAB_NAMES[tabName]}
            {[
              CompanyCharTabName.historicalData,
              CompanyCharTabName.projection,
            ].includes(tabName as CompanyCharTabName) && <Pill>Client</Pill>}
            {[CompanyCharTabName.syndication].includes(
              tabName as CompanyCharTabName
            ) && <Pill info>Syndication</Pill>}
            {[CompanyCharTabName.portfolioAnalytics].includes(
              tabName as CompanyCharTabName
            ) && <Pill info>for PA Investor</Pill>}
          </styles.MenuItem>
        ))
      }
    >
      Analytics
    </styles.AnalyticsTabLabel>
  );
};

type Props = {
  company: Company;
};

export const Analytics = ({ company }: Props) => {
  const flags = useFlags();
  const { chart: chartTabName = '' }: { chart?: string } = useQuery();
  const { data: chartData, isLoading: chartIsLoading } = useApiQuery(
    getChartsByTabName,
    {
      params: {
        companyId: company.id,
        tabName: chartTabName,
      },
      enabled: flags.enabled('use-react-query'),
    }
  );
  const { data: sagaChartData, loaded: sagaChartDataLoaded } = useCharts(
    company,
    chartTabName
  );

  const data = flags.enabled('use-react-query') ? chartData : sagaChartData;
  const loaded = flags.enabled('use-react-query')
    ? !chartIsLoading
    : sagaChartDataLoaded;

  if (!loaded || !data) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <PageTitle title={TAB_NAMES[chartTabName]} />
      <ChartList charts={data} />
    </>
  );
};
