import React from 'react';
import { Helmet } from 'react-helmet';
import { CompanyType, InvestorSignupPages, AccountKind } from '@hum/types';
import { env, analytics } from '@hum/common';
import { TextInputField, CheckboxField } from '@hum/legacy-ui';
import { Button, Icon, Text, TextType, Topbar } from '@hum/ui-library';
import { useSignupForm } from '../hooks/useSignupForm';
import { useSignupPage } from './useSignupPage';

import {
  trackGoogleAdsConversion,
  GoogleAdsConversions,
  trackLinkedInAdsConversion,
  LinkedInAdsConversions,
} from '@hum/icm-app/src/utils';

const AccountKindCompanyRoleMap = {
  [AccountKind.CreditInvesting]: CompanyType.SyndicationInvestor,
  [AccountKind.PortfolioAnalytics]: CompanyType.Investor,
};

export const InvestorSignupPage = () => {
  const { title, page, accountKinds, onSignup } = useSignupPage();
  let content;

  if (page === InvestorSignupPages.Done) {
    content = <DoneStep />;
  } else {
    content = (
      <div className="w-full max-w-md mx-auto max-md:pb-6">
        {page === InvestorSignupPages.Form && (
          <FormStep accountKinds={accountKinds} onSignup={onSignup} />
        )}
      </div>
    );
  }

  return (
    <div className="w-full">
      <Topbar
        logo={<Icon.Hum />}
        title={`| ${title}`}
        userLinks={
          page === InvestorSignupPages.Done ? (
            <a
              href="mailto: hello@humcapital.com"
              className="ui-link text-bodyMicoSize"
            >
              Contact us
            </a>
          ) : (
            <a href="/login" className="ui-link text-bodyMicoSize">
              Log in
            </a>
          )
        }
      />
      {content}
    </div>
  );
};

export type FormStepProps = {
  onSignup: () => void;
  accountKinds: AccountKind[];
};

const FormStep = ({ onSignup, accountKinds }: FormStepProps) => {
  const { form, fields } = useSignupForm({
    onSuccess: onSignup,
    companyRoles: accountKinds.map((kind) => AccountKindCompanyRoleMap[kind]),
  });

  const itemsField = [
    {
      testId: 'company-input',
      label: 'Firm name',
      property: 'company',
    },
    { testId: 'email-input', label: 'Email', property: 'email' },
    { testId: 'firstname-input', label: 'First name', property: 'firstName' },
    { testId: 'lastname-input', label: 'Last name', property: 'lastName' },
  ];

  return (
    <>
      <Helmet
        title={`Investor signup for the Hum Capital funding marketplace`}
      />
      <div className="py-4 max-sm:px-4 max-w-[400px] mx-auto w-full mt-10">
        <Text type={TextType.DISPLAY_S} className="mb-2">
          Join Hum Capital
        </Text>
        <Text type={TextType.BODY_M} subdued>
          Get a personalized deal flow that matches your funding requirements
          with Hum Capital
        </Text>
      </div>
      <div className="max-w-[400px] mx-auto w-full mb-6 max-sm:p-4">
        <form onSubmit={form.handleSubmit} role="form">
          {itemsField.map((item) => (
            <TextInputField
              key={item.testId}
              label={item.label}
              inputTestId={item.testId}
              {...fields[item.property]}
            />
          ))}
          <CheckboxField
            data-testid="company_signup:accept_tos_checkbox"
            {...fields.acceptedTOS}
            spaceBottom
            label={
              <>
                I accept Hum Capital's{' '}
                <a
                  href={`https://${env.CAPITAL_MAIN_DOMAIN}/terms-of-service`}
                  data-testid="company_signup:link:accept_tos"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    // @ts-ignore
                    analytics.track('signup:view-tos');
                    // because <a> is inside this button, if user clicks view ToS prevent checkbox from turning on
                    e.stopPropagation();
                  }}
                >
                  terms of service
                </a>
              </>
            }
          />
          <Button
            fullWidth
            submit
            disabled={fields.acceptedTOS.value === false}
          >
            Request access
          </Button>
        </form>
      </div>
    </>
  );
};

const DoneStep = () => {
  trackGoogleAdsConversion(GoogleAdsConversions.investorSignupSubmit);
  trackLinkedInAdsConversion(LinkedInAdsConversions.investorSignupSubmit);

  return (
    <>
      <div className="h-full">
        <div className="w-full md:max-w-[500px] mx-auto text-center px-4 mt-10">
          <Text type={TextType.PAGE_HEADING}>Check your email!</Text>
          <Text type={TextType.BODY_M} subdued className="mt-2">
            Thank you for joining, Hum! Please check your email to verify your
            account.
          </Text>
          <Text type={TextType.BODY_M} subdued className="mt-4">
            Our team will reach out to you as soon as possible to learn more
            about your investment preferences
          </Text>
        </div>
        <div className="grid max-w-5xl grid-cols-3 gap-6 px-4 pb-12 mx-auto mt-12">
          <div className="col-span-3 md:col-span-1 md:mx-auto md:max-w-[200px] max-sm:text-center">
            <Icon.InvestorSignupStepOne className="max-w-[200px] mb-5 max-sm:mx-auto" />
            <Text type={TextType.HEADING_M}>Access deal flow</Text>
            <Text type={TextType.BODY_S} subdued className="mt-1">
              Get a personalized deal flow that the market can’t see.
            </Text>
          </div>
          <div className="col-span-3 md:col-span-1 md:mx-auto md:max-w-[200px] max-sm:text-center">
            <Icon.InvestorSignupStepTwo className="max-w-[200px] mb-5 max-sm:mx-auto" />
            <Text type={TextType.HEADING_M}>Analyze & decide</Text>
            <Text type={TextType.BODY_S} subdued className="mt-1">
              Leverage real-time insights and accelerate the due diligence
              process.
            </Text>
          </div>
          <div className="col-span-3 md:col-span-1 md:mx-auto md:max-w-[200px] max-sm:text-center">
            <Icon.InvestorSignupStepThree className="max-w-[200px] mb-5 max-sm:mx-auto" />
            <Text type={TextType.HEADING_M}>Fund & repeat</Text>
            <Text type={TextType.BODY_S} subdued className="mt-1">
              Unlock a wide range of opportunities and increase coverage without
              increasing your footprint.
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};
