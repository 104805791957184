import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import { AccountKind, InvestorSignupPages } from '@hum/types';

export const useSignupPage = () => {
  const firstPage = InvestorSignupPages.Form;
  const [
    { accountKind = AccountKind.CreditInvesting, page = firstPage },
    updateQuery,
  ] = useUrlQuery();

  const accountKinds = Array.isArray(accountKind) ? accountKind : [accountKind];

  const onContinue = () => {
    updateQuery({ page: InvestorSignupPages.Form });
  };

  const onSignup = () => {
    updateQuery({ page: InvestorSignupPages.Done });
  };

  return {
    title: 'Investor',
    done: false,
    page,
    accountKinds: accountKinds as AccountKind[],
    onContinue,
    onSignup,
  };
};
