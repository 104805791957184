import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Company } from '@hum/types';
import { adminEditCompanyFormClosed } from '@hum/icm-app/src/actions';
import { Modal } from '@hum/design-system';
import { Field, SelectInput, Box } from '@hum/legacy-ui';
import { Button, InfoSection } from '@hum/ui-library';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

export const SyndicationUnwantedInvestors = ({
  company,
}: {
  company: Company;
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [unwantedInvestors, setUnwantedInvestors] = useState(
    company.application.unwantedInvestors || []
  );
  const dispatch = useDispatch();
  const api = useAPI();

  useEffect(() => {
    if (company.id && showUpdateModal) {
      api.onboarding.saveUnwantedInvestorsDispatch()(company.id, {
        unwantedInvestors,
      });
    }
  }, [unwantedInvestors]);

  const closeModal = () => {
    setShowUpdateModal(false);
    dispatch(adminEditCompanyFormClosed({ companyId: company.id }));
  };

  return (
    <>
      <InfoSection
        title="Unwanted investors"
        primaryAction={{
          text: 'Update unwanted investors',
          onClick: () => setShowUpdateModal(true),
        }}
      >
        {company.application.unwantedInvestors?.length ? (
          <ul data-testid="company-info:unwanted-investors">
            {company.application.unwantedInvestors.map((investor) => (
              <li key={investor.label}>{investor.value}</li>
            ))}
          </ul>
        ) : (
          'N/A'
        )}
      </InfoSection>
      {showUpdateModal && (
        <Modal
          header="Update unwanted investors"
          onClose={closeModal}
          onSubmit={closeModal}
        >
          <Field
            v3
            label="Please list the investors you don't want to see"
            value={unwantedInvestors}
            onValueChange={setUnwantedInvestors}
          >
            {(props) => (
              <SelectInput
                {...props}
                aria-label="Unwanted investors"
                placeholder="Unwanted investors"
                options={[]}
                isMulti
                isCreatable
                isMultiClearable
                noOptionsMessage={() => 'Enter the name of the investors'}
                formatCreateLabel={(value) => `Add investor: ${value}`}
              />
            )}
          </Field>
          <Box>
            <Button onClick={closeModal}>Done</Button>
          </Box>
        </Modal>
      )}
    </>
  );
};
