import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { CheckboxField, InputMode, TextInputField } from '@hum/legacy-ui';
import {
  Button,
  Icon,
  OnboardingLayout,
  Text,
  TextType,
} from '@hum/ui-library';
import { TocModal } from '@hum/icm-app/src/components/TocModal';
import { useSetPassword } from '@hum/icm-app/src/pages/auth/hooks/useSetPassword';
import { useIsSvbSubDomain } from '@hum/icm-app/src/hooks/useIsSvbSubdomain';
import termsOfServiceContent from '@hum/icm-app/src/pages/onboarding-v4/agreements/Hum_capital_tos_06_15_21.md';
import svbTermsOfServiceContent from '@hum/icm-app/src/pages/auth/agreements/SVB_tos_2021_06_16.md';

export const SetPassword = ({}: { isWhitelabeled: boolean }) => {
  const {
    form,
    fields,
    tosIsAccepted,
    reviewedCommunicationsOptIn,
    tosIsAcceptedLoaded,
  } = useSetPassword();
  const [isOpen, setIsOpen] = useState(false);
  const isSvb = useIsSvbSubDomain();
  if (!tosIsAcceptedLoaded) {
    return null;
  }
  return (
    <>
      <Helmet title="Set password" />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title="Set password" />
          <OnboardingLayout.Footer>
            <></>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <TextInputField
                {...fields.password.input}
                inputTestId="password-input"
                inputMode={InputMode.PASSWORD}
                label="New password"
                infoText="Must contain 10 characters or more"
              />
              {!reviewedCommunicationsOptIn && (
                <CheckboxField
                  {...fields.acceptedCommunications}
                  className="signUpToggle boxOnTop"
                  boxOnTop
                  data-testid="company_signup:accept_communications_opt_in_checkbox"
                  label={'I agree to receive communications from Hum Capital'}
                />
              )}

              <Button
                fullWidth
                submit
                loading={form.isSubmitting}
                testId="reset_password:button:send_link"
              >
                Set password
              </Button>
              {!tosIsAccepted && (
                <div className="mt-10">
                  <Text type={TextType.BODY_MICO}>
                    By continuing, you agree to the Hum Capital{' '}
                    <a
                      href="https://humcapital.com/terms-of-service/"
                      target="blank"
                      className="ui-link text-bodyMicoSize"
                    >
                      terms of service
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://humcapital.com/privacy-policy/"
                      target="blank"
                      className="ui-link text-bodyMicoSize"
                    >
                      privacy policy.
                    </a>
                  </Text>
                </div>
              )}
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/logout" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
      <TocModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onBtnClick={() => form.setFieldValue('acceptedTOS', true)}
        content={isSvb ? svbTermsOfServiceContent : termsOfServiceContent}
        title="Terms of Service"
        buttonText="I accept and agree"
        data-testid="onboarding:nda"
      />
    </>
  );
};
