import React from 'react';
import * as Yup from 'yup';
import { useForm, useField } from '@hum/common/src/modules/form';
import { User, ChannelType } from '@hum/types';
import { Modal } from '@hum/design-system';
import { Button, Scrollable } from '@hum/ui-library';
import { TextInputField } from '@hum/legacy-ui';
import {
  adminInviteUserFormSubmitted,
  adminUpdateUserFormSubmitted,
} from '@hum/icm-app/src/actions';
import { useDispatch } from 'react-redux';
import { createCompanyUser, updateCompanyUser, useApiMutation } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  position: '',
  phone: '',
};

// prettier-ignore
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Please enter a valid email').required('Required'),
  position: Yup.string().required('Required'),
  phone: Yup.string().nullable(),
})

const useUserModal = ({
  companyId,
  closeModal,
  isUpdate,
  currentUser,
  companyType,
}: {
  companyId: number;
  closeModal: () => void;
  refetch: () => void;
  isUpdate: boolean;
  currentUser: User;
  companyType: string;
}) => {
  const dispatch = useDispatch();
  const flags = useFlags();
  const { mutate: createUser } = useApiMutation(createCompanyUser);

  const { mutate: updateUser } = useApiMutation(updateCompanyUser);

  const form = useForm({
    initialValues: isUpdate ? currentUser : initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (user: Partial<User>, { resetForm }) => {
      const cleanUser = Object.keys(initialValues).reduce(
        (picked, key) => ({ ...picked, [key]: user && user[key] }),
        {}
      ) as User;
      const { position, ...rest } = cleanUser;
      const userId = user.id!;
      if (!isUpdate) {
        cleanUser.channel =
          companyType == 'company'
            ? ChannelType.icm_company_invitation
            : ChannelType.icm_investor_invitation;
        if (flags.enabled('use-react-query')) {
          createUser({
            companyId,
            companyPosition: position,
            ...rest,
          });
        } else {
          dispatch(
            adminInviteUserFormSubmitted({ payload: cleanUser, companyId })
          );
        }
      } else {
        if (flags.enabled('use-react-query')) {
          updateUser({
            companyId,
            userId,
            companyPosition: position,
            ...rest,
          });
        } else {
          dispatch(
            adminUpdateUserFormSubmitted({
              payload: { ...cleanUser, userId },
              companyId,
            })
          );
        }
      }
      resetForm();
      closeModal();
    },
  });

  const fields = {
    firstName: useField('firstName', 'text', form).input,
    lastName: useField('lastName', 'text', form).input,
    email: useField('email', 'text', form).input,
    phone: useField('phone', 'text', form).input,
    position: useField('position', 'text', form).input,
  };

  return {
    form,
    fields,
  };
};

export const AddUser = ({
  isVisible,
  companyId,
  companyType,
  closeModal,
  refetch,
  currentUser,
}: {
  isVisible: boolean;
  companyId: number;
  companyType: string;
  closeModal: () => void;
  refetch: () => void;
  currentUser: User;
}) => {
  const isUpdate = !!currentUser?.id;

  const { form, fields } = useUserModal({
    companyId,
    closeModal,
    refetch,
    isUpdate,
    currentUser,
    companyType,
  });

  return (
    <Modal
      side
      wide
      visible={isVisible}
      onClose={closeModal}
      header={`${isUpdate ? 'Update' : 'Add'} user`}
      footer={
        <>
          <Button
            onClick={() => {
              form.resetForm();
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button submit onClick={form.handleSubmit}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </>
      }
    >
      <Scrollable>
        <TextInputField
          label="First Name"
          inputTestId="adduser:firstName"
          {...fields.firstName}
        />
        <TextInputField
          label="Last Name"
          inputTestId="adduser:lastName"
          {...fields.lastName}
        />
        <TextInputField
          label="Email"
          inputTestId="adduser:email"
          {...fields.email}
        />
        <TextInputField
          label="Phone"
          inputTestId="adduser:phone"
          {...fields.phone}
        />
        <TextInputField
          label="Position"
          inputTestId="adduser:position"
          {...fields.position}
        />
      </Scrollable>
    </Modal>
  );
};
