import React, { useEffect, useState } from 'react';

import { Pill, Checkbox, Table, TableSubtext } from '@hum/legacy-ui';
import { Button, ButtonType, Empty } from '@hum/ui-library';
import { CompanyType } from '@hum/types';
import {
  PortfolioMonitoringInvite,
  PortfolioMonitoringInviteForm,
  PortfolioMonitoringStatusForm,
  PorfolioMonitoringInviteStatus,
} from '@hum/icm-app/src/state';
import { PM_invitedCompaniesUsed } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  CreateInviteModal,
  defaultValues,
} from '@hum/icm-app/src/pages/portfolio/CreateInviteModal';
import { UpdateStatusModal } from '@hum/icm-app/src/pages/portfolio/UpdateStatusModal';
import * as inviteStyles from './styles.pc';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  bulkCompanyStatusUpdate,
  getPortfolioInvites,
  useApiMutation,
  useApiQuery,
} from '@hum/api';
import { Result } from '@hum/common';

export const useInvestorInvites = (companyId: number) => {
  const flags = useFlags();
  const { data: investorPortfolioInvites } = useApiQuery(getPortfolioInvites, {
    params: { companyId },
  });
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query')) {
      dispatch(PM_invitedCompaniesUsed({ companyId }));
    }
  }, [companyId]);

  return flags.enabled('use-react-query')
    ? ({ data: investorPortfolioInvites } as Result<
        PortfolioMonitoringInvite[]
      >)
    : state.investorPortfolioInvites;
};

const TableStats = ({
  inviteCount,
  selected,
  companyId,
  clearSelected,
}: {
  inviteCount: number;
  selected: number[];
  companyId: number;
  clearSelected: () => void;
}) => {
  const flags = useFlags();
  const { mutate: updateStatusCompany } = useApiMutation(
    bulkCompanyStatusUpdate,
    {
      onSuccess: () => {
        clearSelected();
      },
    }
  );
  const { dispatch } = useAppStore();
  const api = useAPI();

  const inviteCompanies = async () => {
    await api.portfolioMonitoring.bulkCompanyStatusUpdate(dispatch)(
      companyId,
      selected
    );
    clearSelected();
  };

  return (
    <inviteStyles.InviteActions label={`${selected.length} companies selected`}>
      <Button
        onClick={() =>
          flags.enabled('use-react-query')
            ? updateStatusCompany({ companyId, inviteIds: selected })
            : inviteCompanies
        }
        type={!!inviteCount ? ButtonType.PRIMARY : ButtonType.SECONDARY}
        disabled={!selected.length}
        testId="portfolio:send-invites-button"
      >
        Send Invites
      </Button>
    </inviteStyles.InviteActions>
  );
};

export const InvitedCompanies = ({
  companyId,
  inviteModalOpen,
  onInviteModalClose,
}: {
  companyId: number;
  inviteModalOpen?: boolean;
  onInviteModalClose?: () => void;
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const {
    state: { session },
  } = useAppStore();
  const { data: invites = [] } = useInvestorInvites(companyId);
  const [updateInvite, setUpdateInvite] = useState<
    PortfolioMonitoringInviteForm & { id?: number }
  >(defaultValues);
  const [updateStatus, setUpdateStatus] = useState<
    PortfolioMonitoringStatusForm & { id?: number }
  >();
  const isAdmin = session.data?.roles.includes(CompanyType.Admin);
  const updateIsOpen = !!updateInvite.contactEmail.length;
  const emptyMessage = isAdmin
    ? 'Import companies using CSV file or wait for investor to create invites.'
    : 'Create invites for companies using the Add Company button, send invites to companies using the Send Invites button.';

  const toggle = (id: number) => {
    if (selected.includes(id)) {
      setSelected([...selected.filter((i) => i !== id)]);
    } else {
      setSelected([...selected, id]);
    }
  };

  const onEditButtonClick = (invite: PortfolioMonitoringInvite) => {
    setUpdateInvite({
      id: invite.id,
      companyName: invite.companyName,
      companyUrl: invite.companyUrl,
      contactFirstName: invite.contactFirstName,
      contactLastName: invite.contactLastName,
      contactEmail: invite.contactEmail,
      contactPosition: invite.contactPosition,
      investedCapital: invite.investedCapital || 0,
      dateOfFirstInvestment:
        invite.dateOfFirstInvestment?.substring(0, 10) || '',
    });
  };

  const onSetStatusButtonClick = (invite: PortfolioMonitoringInvite) => {
    setUpdateStatus({ id: invite.id, status: invite.status });
  };

  const closeInviteModal = () => {
    if (onInviteModalClose) {
      onInviteModalClose();
    }
    setUpdateInvite(defaultValues);
  };

  const closeStatusModal = () => {
    setUpdateStatus(undefined);
  };

  return (
    <div>
      {!!invites.length ? (
        <Table
          itemKey="contactEmail"
          defaultSortKey="status"
          secondary
          cellConfig={{
            name: {
              label: 'Company',
              getSortValue(item) {
                return item.companyName;
              },
              renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Checkbox
                        value={
                          selected.includes(item.id) ||
                          !(
                            item.status ===
                            PorfolioMonitoringInviteStatus.Created
                          )
                        }
                        onValueChange={() => toggle(item.id)}
                        disabled={
                          !(
                            item.status ===
                            PorfolioMonitoringInviteStatus.Created
                          )
                        }
                      />
                    </div>
                    <span style={{ marginLeft: 18 }}>
                      {isAdmin ? (
                        <a
                          href={item.companyUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.companyName}
                        </a>
                      ) : (
                        item.companyName
                      )}
                    </span>
                  </div>
                );
              },
            },
            contact: {
              label: 'Point of Contact',
              getSortValue(item) {
                return item.companyName;
              },
              renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
                return (
                  <div>
                    {`${item.contactFirstName} ${item.contactLastName}`}
                    <br />
                    <TableSubtext>{item.contactEmail}</TableSubtext>
                  </div>
                );
              },
            },
            ...(isAdmin
              ? {
                  status: {
                    label: 'Status',
                    renderer: ({
                      item,
                    }: {
                      item: PortfolioMonitoringInvite;
                    }) => (
                      <>
                        <Pill
                          notice={
                            item.status !==
                            PorfolioMonitoringInviteStatus.Created
                          }
                        >
                          {item.status}
                        </Pill>
                        <Button
                          type={ButtonType.SECONDARY}
                          onClick={() => onSetStatusButtonClick(item)}
                        >
                          Set Status
                        </Button>
                      </>
                    ),
                  },
                }
              : {}),
            actions: {
              label: 'Actions',
              renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
                return (
                  <Button
                    type={ButtonType.SECONDARY}
                    onClick={() => onEditButtonClick(item)}
                    disabled={[
                      PorfolioMonitoringInviteStatus.Pending,
                      PorfolioMonitoringInviteStatus.Sent,
                      PorfolioMonitoringInviteStatus.Viewed,
                      PorfolioMonitoringInviteStatus.Accepted,
                    ].includes(item.status)}
                  >
                    Update
                  </Button>
                );
              },
            },
          }}
          data={invites}
          allowSearchBy={[
            'companyName',
            'contactFirstName',
            'contactLastName',
            'contactEmail',
          ]}
        />
      ) : (
        <Empty message={emptyMessage} />
      )}

      <TableStats
        selected={selected}
        companyId={companyId}
        inviteCount={invites.length}
        clearSelected={() => setSelected([])}
      />

      <CreateInviteModal
        companyId={companyId}
        isOpen={inviteModalOpen || updateIsOpen || true}
        onClose={closeInviteModal}
        invite={updateInvite as any}
      />

      <UpdateStatusModal
        companyId={companyId}
        isOpen={!!updateStatus?.id}
        onClose={closeStatusModal}
        invite={updateStatus as any}
      />
    </div>
  );
};
