import { useEffect } from 'react';
import * as Yup from 'yup';
import { pickBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

import { useField, useForm } from '@hum/common/src/modules/form';
import { reviewAndConfirmFormSubmitted } from '@hum/icm-app/src/actions';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import {
  FUNDING_TYPE,
  FUNDRAISING_TIME_FRAME,
  INVESTMENT_AMOUNT,
  USE_OF_FUNDS,
  CAPITAL_DEPLOYMENT,
  validationSchema as aboutYouValidationSchema,
} from '@hum/icm-app/src/pages/auth/choose-your-adventure/AboutYou/useShareYourGoals';
import {
  COUNTRY,
  STATE,
  YEAR_FOUNDED,
  SELF_REPORTED_INDUSTRY,
  REVENUE_MODEL,
  validationSchema as aboutYourBusinessValidationSchema,
} from '@hum/icm-app/src/pages/auth/choose-your-adventure/AboutYourBusiness/useAboutYourBusiness';
import {
  LAST_STAGE_FUNDING,
  DATE_OF_LAST_RAISE,
  LAST_VALUATION,
  TOTAL_EQUITY_RAISED,
  validationSchema as fundraisingGoalsValidationSchema,
} from '@hum/icm-app/src/pages/auth/choose-your-adventure/FundraisingGoals/useFundraisingHistory';
import {
  WORK_EMAIL,
  FIRST_NAME,
  LAST_NAME,
  COMPANY_ROLE,
  COMPANY_NAME,
  WEBSITE,
  validationSchema as chooseYourAdventureValidationSchema,
} from '@hum/icm-app/src/pages/auth/hooks/useChooseYourAdventureSignUp';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';
import { LastFundingRound } from '@hum/types';
import { toast } from '@hum/common/src/modules/toast';
import { useAnalyticsEvent } from '@hum/api';

const {
  password,
  website,
  ...chooseYourAdventureFields
} = chooseYourAdventureValidationSchema.fields as any;
const validationSchema = Yup.object().shape({
  ...aboutYouValidationSchema.fields,
  ...aboutYourBusinessValidationSchema.fields,
  ...fundraisingGoalsValidationSchema.fields,
  ...chooseYourAdventureFields,
  [WEBSITE]: Yup.string()
    .trim()
    .matches(URL_REGEX, 'Please enter a valid URL')
    .required('Required'),
});

const INITIAL_VALUES = {
  [WORK_EMAIL]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [COMPANY_ROLE]: '',
  [FUNDRAISING_TIME_FRAME]: '',
  [INVESTMENT_AMOUNT]: '',
  [USE_OF_FUNDS]: '',
  [FUNDING_TYPE]: '',
  [LAST_STAGE_FUNDING]: '',
  [DATE_OF_LAST_RAISE]: '',
  [TOTAL_EQUITY_RAISED]: '',
  [LAST_VALUATION]: '',
  [COMPANY_NAME]: '',
  [COUNTRY]: '',
  [STATE]: '',
  [WEBSITE]: '',
  [YEAR_FOUNDED]: '',
  [SELF_REPORTED_INDUSTRY]: '',
  [REVENUE_MODEL]: '',
};

export function useReviewAndConfirm() {
  const dispatch = useDispatch();
  const trackEvent = useAnalyticsEvent();
  const { state } = useAppStore();
  const currentUser = state.session.data;
  const company = useCurrentCompany();
  const dynamicValidationCountrySchema = () => {
    return validationSchema.shape({
      [COUNTRY]: Yup.string().required('Required'),
      [STATE]: Yup.string().notRequired(),
    });
  };
  const form = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema: dynamicValidationCountrySchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (payload) => {
      const { yearFounded, website, dateOfLastRaise } = payload;
      const fullWebsite =
        website.startsWith('http://') || website.startsWith('https://')
          ? website.toLowerCase()
          : `https://${website.toLowerCase()}`;
      let formattedDate = '';

      if (dateOfLastRaise) {
        formattedDate = `${dateOfLastRaise
          .toString()
          .slice(0, 2)}-01-${dateOfLastRaise.toString().slice(2)}`;
      }
      dispatch(
        reviewAndConfirmFormSubmitted({
          companyId: company.data!.id,
          payload: {
            companyPosition: payload[COMPANY_ROLE],
            website: fullWebsite,
            country: payload[COUNTRY],
            state: payload[STATE],
            application: pickBy({
              revenueModel: payload[REVENUE_MODEL],
              selfReportedIndustry: payload[SELF_REPORTED_INDUSTRY],
              yearFounded: Number(yearFounded),
              investmentAmount: payload[INVESTMENT_AMOUNT],
              fundraisingTimeFrame: payload[FUNDRAISING_TIME_FRAME],
              useOfFunds: payload[USE_OF_FUNDS],
              capitalDeployment: payload[CAPITAL_DEPLOYMENT],
              fundingType: payload[FUNDING_TYPE],
              [LAST_STAGE_FUNDING]: payload[LAST_STAGE_FUNDING],
              [DATE_OF_LAST_RAISE]:
                formattedDate != ''
                  ? new Date(formattedDate).toISOString()
                  : '',
              [LAST_VALUATION]: payload[LAST_VALUATION],
              [TOTAL_EQUITY_RAISED]: payload[TOTAL_EQUITY_RAISED],
              reviewAndConfirmBy: currentUser?.email,
            }),
          },
        })
      );
    },
  });

  useEffect(() => {
    trackEvent('company-visit-review-and-confirm');
  }, []);

  useEffect(() => {
    if (form.submitCount > 0 && !form.isValid) {
      toast.error('Ensure required fields are completed.');
    }
  }, [form.submitCount]);

  //values we may have from admin
  useEffect(() => {
    if (currentUser?.email) {
      form.setFieldValue(WORK_EMAIL, currentUser?.email);
    }

    if (currentUser?.firstName) {
      form.setFieldValue(FIRST_NAME, currentUser?.firstName);
    }
    if (currentUser?.lastName) {
      form.setFieldValue(LAST_NAME, currentUser?.lastName);
    }
    if (currentUser?.position) {
      form.setFieldValue(COMPANY_ROLE, currentUser?.position);
    }

    if (company.data?.website) {
      form.setFieldValue(WEBSITE, company.data?.website);
    }
    if (company.data?.country) {
      form.setFieldValue(COUNTRY, company.data?.country);
    }
    if (company.data?.state) {
      form.setFieldValue(STATE, company.data?.state);
    }
    if (company.data?.name) {
      form.setFieldValue(COMPANY_NAME, company.data?.name);
    }
    if (company.data?.application.investmentAmount) {
      form.setFieldValue(
        INVESTMENT_AMOUNT,
        company.data?.application.investmentAmount
      );
    }
    if (company.data?.application.fundraisingTimeFrame) {
      form.setFieldValue(
        FUNDRAISING_TIME_FRAME,
        company.data?.application.fundraisingTimeFrame
      );
    }
    if (company.data?.application.useOfFunds) {
      form.setFieldValue(USE_OF_FUNDS, company.data?.application.useOfFunds);
    }
    if (company.data?.application.capitalDeployment) {
      form.setFieldValue(
        CAPITAL_DEPLOYMENT,
        company.data?.application.capitalDeployment
      );
    }
    if (company.data?.application.fundingType) {
      form.setFieldValue(FUNDING_TYPE, company.data?.application.fundingType);
    }

    if (company.data?.application.yearFounded) {
      form.setFieldValue(YEAR_FOUNDED, company.data?.application.yearFounded);
    }
    if (company.data?.application.selfReportedIndustry) {
      form.setFieldValue(
        SELF_REPORTED_INDUSTRY,
        company.data?.application.selfReportedIndustry
      );
    }
    if (company.data?.application.revenueModel) {
      form.setFieldValue(REVENUE_MODEL, company.data?.application.revenueModel);
    }

    if (company.data?.application.lastFundingRound) {
      form.setFieldValue(
        LAST_STAGE_FUNDING,
        company.data?.application.lastFundingRound
      );
    }
    if (company.data?.application.lastRaised) {
      form.setFieldValue(
        DATE_OF_LAST_RAISE,
        company.data?.application.lastRaised
      );
    }
    if (company.data?.application.totalEquityRaised) {
      form.setFieldValue(
        TOTAL_EQUITY_RAISED,
        company.data?.application.totalEquityRaised
      );
    }
    if (company.data?.application.latestPostMoneyValuation) {
      form.setFieldValue(
        LAST_VALUATION,
        company.data?.application.latestPostMoneyValuation
      );
    }
  }, [company.data, currentUser]);

  const fundraisingTimeFrame = useField(FUNDRAISING_TIME_FRAME, 'select', form)
    .input;
  const investmentAmount = useField(INVESTMENT_AMOUNT, 'text', form).input;
  const useOfFunds = useField(USE_OF_FUNDS, 'text', form).input;
  const capitalDeployment = useField(CAPITAL_DEPLOYMENT, 'text', form).input;
  const fundingType = useField(FUNDING_TYPE, 'select', form).input;
  const legalBusinessName = useField(COMPANY_NAME, 'text', form).input;
  const website = useField(WEBSITE, 'text', form).input;
  const country = useField(COUNTRY, 'select', form).input;
  const stateLocation = useField(STATE, 'select', form).input;
  const selfReportedIndustry = useField(SELF_REPORTED_INDUSTRY, 'select', form)
    .input;
  const yearFounded = useField(YEAR_FOUNDED, 'text', form).input;
  const revenueModel = useField(REVENUE_MODEL, 'select', form).input;
  const lastStageOfFunding = useField(LAST_STAGE_FUNDING, 'select', form).input;
  const dateOfLastRaise = useField(DATE_OF_LAST_RAISE, 'text', form).input;
  const totalEquityRaised = useField(TOTAL_EQUITY_RAISED, 'select', form).input;
  const lastValuation = useField(LAST_VALUATION, 'text', form).input;
  const workEmail = useField(WORK_EMAIL, 'text', form).input;
  const firstName = useField(FIRST_NAME, 'text', form).input;
  const lastName = useField(LAST_NAME, 'text', form).input;
  const companyRole = useField(COMPANY_ROLE, 'text', form).input;

  const disableAdditionalFields =
    lastStageOfFunding.value === LastFundingRound.Bootstrapped;

  // Disable and reset fields if bootstrapped is selected
  useEffect(() => {
    if (disableAdditionalFields) {
      if (dateOfLastRaise) {
        form.setFieldValue(
          DATE_OF_LAST_RAISE,
          INITIAL_VALUES[DATE_OF_LAST_RAISE]
        );
      }
      if (totalEquityRaised) {
        form.setFieldValue(
          TOTAL_EQUITY_RAISED,
          INITIAL_VALUES[TOTAL_EQUITY_RAISED]
        );
      }
      if (lastValuation) {
        form.setFieldValue(LAST_VALUATION, INITIAL_VALUES[LAST_VALUATION]);
      }
    }
  }, [disableAdditionalFields]);

  return {
    form,
    fields: {
      workEmail,
      firstName,
      lastName,
      companyRole,
      investmentAmount,
      fundraisingTimeFrame,
      useOfFunds,
      capitalDeployment,
      fundingType,
      legalBusinessName,
      website,
      country,
      stateLocation,
      selfReportedIndustry,
      yearFounded,
      revenueModel,
      lastStageOfFunding,
      dateOfLastRaise,
      totalEquityRaised,
      lastValuation,
    },
    disableAdditionalFields,
  };
}
