import React from 'react';
import {
  CheckboxField,
  Row as FormRow,
  InputMode,
  TextInputField,
} from '@hum/legacy-ui';
import {
  OnboardingLayout,
  Button,
  Icon,
  Text,
  TextType,
  Topbar,
  ButtonSize,
} from '@hum/ui-library';

import { useChooseYourAdventureSignUp } from '@hum/icm-app/src/pages/auth/hooks/useChooseYourAdventureSignUp';

export const SignUp = ({ isWhitelabeled }: { isWhitelabeled: boolean }) => {
  const { form, fields } = useChooseYourAdventureSignUp();
  const title = isWhitelabeled
    ? 'Sign in with Hum'
    : 'Create your company profile';

  const subtitle = isWhitelabeled
    ? ''
    : 'Join over 1,000 companies in getting connected to the right capital.';

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/login" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <TextInputField
                label="Work email"
                inputTestId="signup:email"
                {...fields.workEmail}
              />
              <FormRow v3 className="compact">
                <TextInputField
                  label="First name"
                  inputTestId="signup:firstname"
                  {...fields.firstName}
                />
                <TextInputField
                  label="Last name"
                  inputTestId="signup:lastname"
                  {...fields.lastName}
                />
              </FormRow>
              <TextInputField
                label="Job title"
                inputTestId="signup:companyrole"
                {...fields.companyRole}
              />
              <TextInputField
                label="Legal business name"
                inputTestId="signup:businessname"
                {...fields.legalBusinessName}
              />
              <TextInputField
                label="Website"
                inputTestId="signup:website"
                inputAriaLabel="Website"
                {...fields.website}
              />
              <TextInputField
                {...fields.password}
                inputTestId="signup:password"
                inputMode={InputMode.PASSWORD}
                label="Password"
                infoText="Must contain 10 characters or more"
              />
              <CheckboxField
                {...fields.acceptedCommunications}
                className="signUpToggle boxOnTop"
                boxOnTop
                data-testid="company_signup:accept_communications_opt_in_checkbox"
                label={'I agree to receive communications from Hum Capital'}
              />
              <div className="mb-4"></div>
              <Button
                size={ButtonSize.LG}
                submit
                fullWidth
                loading={form.isSubmitting}
                testId="link:signup_page"
              >
                Continue with email
              </Button>
              <div className="mt-10">
                <Text type={TextType.BODY_MICO} subdued>
                  By continuing, you agree to the Hum Capital{' '}
                  <a
                    href="https://humcapital.com/terms-of-service/"
                    target="blank"
                    className="ui-link text-bodyMicoSize"
                  >
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://humcapital.com/privacy-policy/"
                    target="blank"
                    className="ui-link text-bodyMicoSize"
                  >
                    privacy policy.
                  </a>
                </Text>
              </div>
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/login" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
};
