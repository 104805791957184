import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { confirm } from '@hum/common/src/modules/confirm';
import { StatusCodeDot } from '@hum/design-system';
import { User, CompanyContactStatus, CompanyContact } from '@hum/types';
import { Pill, Table, TableCellConfig, TableMenuItem } from '@hum/legacy-ui';
import { Spinner, InfoSection, Empty } from '@hum/ui-library';
import { useCompanyUsers } from '@hum/icm-app/src/hooks/useCompanyUsers';
import { AddUser } from './AddUser';
import {
  adminManageUsersDisableUserButtonClicked,
  adminManageUsersDeleteUserButtonClicked,
  adminManageUsersEnableUserButtonClicked,
  adminManageUsersResendInviteButtonClicked,
  adminManageUsersSetAsPrimaryContactButtonClicked,
} from '@hum/icm-app/src/pages/admin/ducks/actions';
import {
  getCompanyUsers,
  resendInvite,
  useApiMutation,
  useApiQuery,
} from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const TABLE_CELLS: Record<string, TableCellConfig> = {
  name: {
    label: 'name',
    renderer: ({ item: user }: { item: CompanyContact }) => {
      return (
        <>
          {`${user.firstName} ${user.lastName} `}
          {user.isPrimaryContact && <Pill>Primary</Pill>}
        </>
      );
    },
  },
  email: {
    label: 'email',
    renderer: ({ item: user }: { item: CompanyContact }) => <>{user.email}</>,
  },
  acceptedCompanyCommunicationsOptIn: {
    label: 'Accepted communications?',
    renderer: ({ item: user }: { item: CompanyContact }) => (
      <>
        {user.acceptedCompanyCommunicationsOptIn === undefined ||
        user.acceptedCompanyCommunicationsOptIn === null
          ? 'N/A'
          : user.acceptedCompanyCommunicationsOptIn === true
          ? 'Yes'
          : 'No'}
      </>
    ),
  },
  status: {
    label: 'status',
    renderer: ({ item }: { item: CompanyContact }) => (
      <>
        <StatusCodeDot
          active={item.status === CompanyContactStatus.Active}
          disabled={item.status === CompanyContactStatus.Disabled}
          warning={item.status === CompanyContactStatus.Invited}
        />
        {item.status.toUpperCase()}
      </>
    ),
  },
};

const getMenuItems = (user: User): TableMenuItem[] => {
  const userIsInvited = user.status === CompanyContactStatus.Invited;
  const userIsActive = user.status === CompanyContactStatus.Active;
  const userIsDisabled = user.status === CompanyContactStatus.Disabled;
  const userIsPrimaryContact = user.isPrimaryContact;

  return [
    { label: 'Update', value: 'onUpdateClick' },
    ...(userIsInvited
      ? [
          { label: 'Resend Invite', value: 'onResendInviteClick' },
          { label: 'Delete', value: 'onDeleteClick' },
        ]
      : []),
    ...(userIsActive ? [{ label: 'Disable', value: 'onDisableClick' }] : []),
    ...(userIsDisabled ? [{ label: 'Enable', value: 'onEnableClick' }] : []),
    ...(!userIsPrimaryContact
      ? [{ label: 'Set as Primary Contact', value: 'onSetAsPrimaryClick' }]
      : []),
  ];
};

export const Users = () => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const [showModal, setShowModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  const companyId = company.data!.id;

  const { data: sagasUsers, loaded, refetch } = useCompanyUsers(
    company.data?.id!
  );
  const { data: companyUsers } = useApiQuery(getCompanyUsers, {
    params: { companyId },
    enabled: flags.enabled('use-react-query'),
  });
  const { mutate: resendUserInvite } = useApiMutation(resendInvite);

  const users = flags.enabled('use-react-query') ? companyUsers : sagasUsers;

  if (!loaded && users !== undefined && users.length === 0) {
    return <Spinner fullScreen />;
  }

  const openModal = (userId?: number) => {
    if (userId) {
      setCurrentUserId(userId);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentUserId(0);
    setShowModal(false);
  };

  const selectedUser = users?.find(
    (employee) => employee.id === currentUserId
  ) as User;

  return (
    <>
      <InfoSection
        title="Manage Team"
        primaryAction={{
          text: 'Invite',
          onClick: openModal,
        }}
        testId="manage-team"
      />
      {users?.length === 0 ? (
        <Empty message="There are no users registered at this time." />
      ) : (
        <Table
          itemKey="id"
          data={users || []}
          dontSortByDefault
          cellConfig={TABLE_CELLS}
          secondary
          onMenuItemClick={(
            option:
              | 'onUpdateClick'
              | 'onDisableClick'
              | 'onDeleteClick'
              | 'onEnableClick'
              | 'onSetAsPrimaryClick'
              | 'onResendInviteClick',
            user: User
          ) => {
            const options = {
              onUpdateClick: () => openModal(user.id),
              onDisableClick: () =>
                dispatch(
                  adminManageUsersDisableUserButtonClicked({
                    user,
                    companyId: company.data?.id!,
                  })
                ),
              onDeleteClick: () =>
                dispatch(
                  adminManageUsersDeleteUserButtonClicked({
                    user,
                    companyId: company.data?.id!,
                  })
                ),
              onEnableClick: () =>
                dispatch(
                  adminManageUsersEnableUserButtonClicked({
                    user,
                    companyId: company.data?.id!,
                  })
                ),
              onSetAsPrimaryClick: async () => {
                if (
                  await confirm({
                    message:
                      'Are you sure you want to make this user primary contact?',
                    danger: true,
                  })
                ) {
                  dispatch(
                    adminManageUsersSetAsPrimaryContactButtonClicked({
                      user,
                      companyId: company.data?.id!,
                    })
                  );
                }
              },
              onResendInviteClick: () =>
                flags.enabled('use-react-query')
                  ? resendUserInvite(user)
                  : dispatch(
                      adminManageUsersResendInviteButtonClicked({ user })
                    ),
            };

            options[option]();
          }}
          getMenuItems={getMenuItems}
        />
      )}
      <AddUser
        isVisible={showModal}
        closeModal={closeModal}
        companyId={company.data?.id!}
        companyType={company.data?.type[0]!}
        refetch={refetch}
        currentUser={selectedUser}
      />
    </>
  );
};
