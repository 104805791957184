import React from "react";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pubDf2A042 from "../../../../../design-system/src/Field.pc";
import _pub5Ab99334 from "../../../../../design-system/src/TextInput.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub14733275 from "../../../../../design-system/src/Checkbox.pc";
import _pubC68937F5, {ScrollablePreview as _pubC68937F5_ScrollablePreview} from "../../../../../design-system/src/Scrollable.pc";
import _pub8Ac8C6B9 from "../../../components/Topbar/index.pc";
import _pub7Abf1313, {ProgressPill as _pub7Abf1313_ProgressPill} from "../../../../../design-system/src/ProgressPills.pc";
import _pub5448A231 from "../../../../../design-system/src/Detail.pc";
import _pubE434Fb6A from "../../../../../design-system/src/Card.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("div", {
    "className": "_6432ceff _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_a8d20e4b _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["children"]
    ),
    props["subtitle"]&&React.createElement("div", {
      "className": "_c66a476 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["subtitle"]
    )
  )
}));
export { Title };

var Step = React.memo(React.forwardRef(function Step(props, ref) {
  return React.createElement("div", {
    "className": "_8d516bca _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["imageStep"],
    React.createElement("div", {
      "className": "_425113d5 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["titleStep"]
    ),
    React.createElement("div", {
      "className": "_35562343 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["descriptionStep"]
    )
  )
}));
export { Step };

var AccountTypeOption = React.memo(React.forwardRef(function AccountTypeOption(props, ref) {
  return React.createElement(_pubE434Fb6A, {
    "class": "_635f0ae6 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
    "spaced": true,
    "onClick": props["onClick"],
    "hover": props["hover"],
    "interactive": true,
  }, 
    React.createElement(_pub14733275, {
      "class": "_d8dc9601 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      "checked": props["checked"],
      "v3": true,
    }, null),
    React.createElement(_pub5448A231, {
      "class": "_41d5c7bb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      "title": props["name"],
      "description": props["description"],
    }, null)
  )
}));
export { AccountTypeOption };

var Wrapper = React.memo(React.forwardRef(function Wrapper(props, ref) {
  return React.createElement("div", {
    "className": "_84e727e1 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Wrapper };

var DonePage = React.memo(React.forwardRef(function DonePage(props, ref) {
  return React.createElement("div", {
    "className": "_a111fc10 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_cee4455d _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_398e10c8 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_a0874172 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        props["subtitle"]
      )
    ),
    props["steps"]&&React.createElement("div", {
      "className": "_cae245ee _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      React.createElement(Step, {
        "class": "_f666e9b5",
        "imageStep": React.createElement("img", {
          "className": "_4f26fecb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/images/step1.svg")),
        }, null),
        "titleStep": "Access deal flow",
        "descriptionStep": "Get a personalized deal flow that the market can’t see.",
      }, null),
      React.createElement(Step, {
        "class": "_6f6fb80f",
        "imageStep": React.createElement("img", {
          "className": "_640bad08 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/images/step2.svg")),
        }, null),
        "titleStep": "Analyze & decide",
        "descriptionStep": "Leverage real-time insights and accelerate the due diligence process.",
      }, null),
      React.createElement(Step, {
        "class": "_18688899",
        "imageStep": React.createElement("img", {
          "className": "_7d109c49 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/images/step3.svg")),
        }, null),
        "titleStep": "Fund & repeat",
        "descriptionStep": "Unlock a wide range of  opportunities and increase coverage without increasing your footprint.",
      }, null)
    )
  )
}));
export { DonePage };

var Header = React.memo(React.forwardRef(function Header(props, ref) {
  return React.createElement(_pub8Ac8C6B9, {
    "class": "_4f1f9d3c _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
    "slim": true,
    "title": props["title"],
    "rightControls": React.createElement(React.Fragment, {
    }, 
      props["done"]&&React.createElement("div", {
        "className": "_4bb43a46 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement("a", {
          "className": "_27050239 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "href": "mailto: hello@humcapital.com",
        }, 
          "Contact us"
          
        )
      ),
      !props["done"]&&React.createElement("div", {
        "className": "_52af0b07 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        "\n      Already have access?\n      "
        ,
        props["accessLink"]
      )
    ),
  }, null)
}));
export { Header };

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("div", {
    "className": "_a67c3809 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(Wrapper, {
    "class": "_48725925" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(Header, {
      "class": "_bd6e0979",
      "title": props["title"],
      "done": props["done"],
      "accessLink": React.createElement("a", {
        "className": "_33b1199c _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "href": "#",
      }, 
        "Log in"
        
      ),
    }, null),
    props["done"]&&React.createElement(DonePage, {
      "class": "_c4a449f1",
      "title": "We've received your request",
      "subtitle": "Your information is being reviewed by our team. We’ll reach out to you within\n        24 hours to set up your account.",
      "steps": true,
    }, null),
    !props["done"]&&React.createElement(_pubC68937F5_ScrollablePreview, {
      "class": "_f6922b73 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      React.createElement(Content, {
        "class": "_ef845d5b",
      }, 
        props["firstStep"]&&React.createElement("div", {
          "className": "_b362af60 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "data-pc-label": "First step",
        }, 
          React.createElement(Title, {
            "class": "_64be1e00",
            "subtitle": "Select the options that best align with you",
          }, 
            "\n          Choose an account\n        "
            
          ),
          React.createElement(AccountTypeOption, {
            "class": "_13b92e96",
            "checked": true,
            "name": "Portfolio analytics",
            "description": "Analyze your portfolio companies in real-time and help them to access non-dilutive financing offers.",
          }, null),
          React.createElement(AccountTypeOption, {
            "class": "_8ab07f2c",
            "hover": true,
            "name": "Credit investing",
            "description": "Source debt investments from our database of over +1,500 companies on our platform.",
          }, null),
          React.createElement(_pub2Dbb4493, {
            "class": "_fdb74fba _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "v3": true,
            "primary": true,
            "wfull": true,
          }, 
            "Continue"
            
          )
        ),
        !props["firstStep"]&&React.createElement("div", {
          "className": "_aa799e21 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        }, 
          React.createElement(Title, {
            "class": "_dc027965",
            "subtitle": "Select the option that best aligns with you",
          }, 
            "\n          Tell us a few more details\n        "
            
          ),
          React.createElement(_pubDf2A042, {
            "class": "_ab0549f3 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "active": true,
            "label": "Firm's legal entity name",
            "v3": true,
          }, 
            React.createElement(_pub5Ab99334, {
              "class": "_77bb98f3 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
              "v3": true,
              "value": "Some company",
            }, null)
          ),
          React.createElement(_pubDf2A042, {
            "class": "_320c1849 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "label": "Email",
            "v3": true,
          }, 
            React.createElement(_pub5Ab99334, {
              "class": "_75fd26aa _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
              "v3": true,
            }, null)
          ),
          React.createElement(_pubDf2A042, {
            "class": "_450b28df _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "label": "Your first name",
            "v3": true,
          }, 
            React.createElement(_pub5Ab99334, {
              "class": "_743f4c9d _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
              "v3": true,
            }, null)
          ),
          React.createElement(_pubDf2A042, {
            "class": "_db6fbd7c _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "label": "Your last name",
            "v3": true,
          }, 
            React.createElement(_pub5Ab99334, {
              "class": "_71705a18 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
              "v3": true,
            }, null)
          ),
          React.createElement(_pub2Dbb4493, {
            "class": "_ac688dea _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "v3": true,
            "primary": true,
            "wfull": true,
          }, 
            "Request access"
            
          )
        )
      )
    )
  )
}));
export { Preview };

var CompanyDetail = React.memo(React.forwardRef(function CompanyDetail(props, ref) {
  return React.createElement("div", {
    "className": "_cf0dfdc7 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_779a480f _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["label"]
    ),
    React.createElement("div", {
      "className": "_ee9319b5 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["value"]
    )
  )
}));
export { CompanyDetail };

var VerifyInformation = React.memo(React.forwardRef(function VerifyInformation(props, ref) {
  return React.createElement("div", {
    "className": "_21039ceb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Header, {
      "class": "_741e9c61",
      "title": props["title"],
      "done": true,
    }, null),
    React.createElement("div", {
      "className": "_ed17cddb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      React.createElement(_pub7Abf1313, {
        "class": "_b73407e4 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_e35c6cc3 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": !props["secondStep"],
        }, null),
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_945b5c55 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": props["secondStep"],
        }, null)
      ),
      React.createElement(Title, {
        "class": "_2e3d565e",
        "subtitle": "We have captured your investment criteria to maximize your deal flow. Take a look!",
      }, 
        "\n      Verify your information\n    "
        
      ),
      React.createElement(_pub5448A231, {
        "class": "_593a66c8 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "title": "About",
        "description": props["description"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_c75ef36b _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Location",
        "description": props["location"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_b059c3fd _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Website",
        "description": props["website"],
        "padded": true,
      }, null),
      React.createElement(Title, {
        "class": "_29509247",
      }, 
        "Investment preferences"
        
      ),
      React.createElement(_pub5448A231, {
        "class": "_5e57a2d1 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Investment range",
        "description": props["investmentRanges"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_cee8bf40 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Investment types",
        "description": props["investmentTypes"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_b9ef8fd6 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Target yearly min. revenue",
        "description": props["yearlyMinRevenue"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_530bcd47 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Target yearly max. revenue",
        "description": props["yearlyMaxRevenue"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_240cfdd1 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        "horizontal": true,
        "title": "Target business types",
        "description": props["businessTypes"],
        "padded": true,
      }, null),
      React.createElement("div", {
        "className": "_bd05ac6b _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_6944a880 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-data-button _pub-97ad4a25_company-data-button _pub-a97d53f3_company-data-button company-data-button",
          "onClick": props["continueOnClick"],
          "v3": true,
          "primary": true,
          "wfull": true,
        }, 
          "\n        Continue\n      "
          
        ),
        React.createElement(_pub2Dbb4493, {
          "class": "_f04df93a _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-data-button _pub-97ad4a25_company-data-button _pub-a97d53f3_company-data-button company-data-button",
          "onClick": props["editOnClick"],
          "v3": true,
          "wfull": true,
        }, 
          "\n        Edit\n      "
          
        )
      )
    )
  )
}));
export { VerifyInformation };

var CompanyData = React.memo(React.forwardRef(function CompanyData(props, ref) {
  return React.createElement("div", {
    "className": "_c86039de _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Header, {
      "class": "_7093e0d3",
      "title": props["title"],
      "done": true,
    }, null),
    React.createElement("div", {
      "className": "_e99ab169 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      React.createElement(_pub7Abf1313, {
        "class": "_3874f244 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_808c59f9 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": !props["secondStep"],
        }, null),
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_f78b696f _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": props["secondStep"],
        }, null)
      ),
      React.createElement(Title, {
        "class": "_a17da3fe",
        "subtitle": "We have captured your investment criteria to maximize your deal flow.",
      }, 
        "\n      Verify your information\n    "
        
      ),
      React.createElement("div", {
        "className": "_d67a9368 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_f40fbd01 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-details-left _pub-97ad4a25_company-details-left _pub-a97d53f3_company-details-left company-details-left",
        }, 
          React.createElement(CompanyDetail, {
            "class": "_ebe172c3",
            "label": "Location",
            "value": props["location"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_9ce64255",
            "label": "Website",
            "value": props["website"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_5ef13ef",
            "label": "Description",
            "value": props["description"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_72e82379",
            "label": "Investment Range",
            "value": props["investmentRanges"],
          }, null)
        ),
        React.createElement("div", {
          "className": "_6d06ecbb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-details-right _pub-97ad4a25_company-details-right _pub-a97d53f3_company-details-right company-details-right",
        }, 
          React.createElement(CompanyDetail, {
            "class": "_e9a7cc9a",
            "label": "Investment Types",
            "value": props["investmentTypes"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_9ea0fc0c",
            "label": "Target Yearly Min Revenue (optional)",
            "value": props["yearlyMinRevenue"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_7a9adb6",
            "label": "Target Yearly Max Revenue (optional)",
            "value": props["yearlyMaxRevenue"],
          }, null),
          React.createElement(CompanyDetail, {
            "class": "_70ae9d20",
            "label": "Target Business Types (optional)",
            "value": props["businessTypes"],
          }, null)
        )
      ),
      React.createElement("div", {
        "className": "_481e06cb _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_f140ab84 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-data-button _pub-97ad4a25_company-data-button _pub-a97d53f3_company-data-button company-data-button",
          "onClick": props["continueOnClick"],
          "v3": true,
          "primary": true,
          "wfull": true,
        }, 
          "\n        Continue\n      "
          
        ),
        React.createElement(_pub2Dbb4493, {
          "class": "_6849fa3e _97ad4a25 _pub-97ad4a25 _pub-a97d53f3" + " " + "_97ad4a25_company-data-button _pub-97ad4a25_company-data-button _pub-a97d53f3_company-data-button company-data-button",
          "onClick": props["editOnClick"],
          "v3": true,
          "wfull": true,
        }, 
          "\n        Edit\n      "
          
        )
      )
    )
  )
}));
export { CompanyData };

var InvestorSetPassword = React.memo(React.forwardRef(function InvestorSetPassword(props, ref) {
  return React.createElement("div", {
    "className": "_266e58f2 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Header, {
      "class": "_731734bd",
      "title": props["headerTitle"],
      "done": true,
    }, null),
    React.createElement("div", {
      "className": "_ea1e6507 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
    }, 
      props["showSteps"]&&React.createElement(_pub7Abf1313, {
        "class": "_c89a8f51 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_998d4282 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": props["firstStep"],
        }, null),
        React.createElement(_pub7Abf1313_ProgressPill, {
          "class": "_ee8a7214 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
          "active": !props["firstStep"],
        }, null)
      ),
      props["firstStep"]&&React.createElement("div", {
        "className": "_e3b7dc92 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_fc3fddfa _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        }, 
          React.createElement(Title, {
            "class": "_261c134b",
            "subtitle": props["subtitle"],
          }, 
            props["title"]
          ),
          props["children"]
        ),
        React.createElement("div", {
          "className": "_65368c40 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
        }, 
          React.createElement("img", {
            "className": "_245aad12 _97ad4a25 _pub-97ad4a25 _pub-a97d53f3",
            "src": getDefault(require("./syndication-set-password-logo.png")),
          }, null)
        )
      )
    )
  )
}));
export { InvestorSetPassword };


