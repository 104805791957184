import { useEffect } from 'react';
import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import * as Yup from 'yup';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { useInvitationRedemption } from '@hum/icm-app/src/hooks/useInvitationRedemption';
import { CompanyType } from '@hum/types';
import { getSubdomain } from '../../../utils';
import { analytics } from '@hum/common';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { SignupMinimalForm } from '@hum/icm-app/src/backend/api/legacy';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  companyName: '',
  invitationHash: undefined,
  isInvestor: false,
  acceptedTOS: false,
};

const signupCheckValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  companyName: Yup.string().required('Required'),
  invitationHash: Yup.string().notRequired(),
  isInvestor: Yup.boolean(),
  acceptedTOS: Yup.boolean().equals([true], 'Required'),
});

type UserSignupFormProps = {
  onSuccess: (payload: SignupMinimalForm) => void;
  companyRoles?: CompanyType[];
};

export const useSignupForm: any = ({
  onSuccess,
  companyRoles = [CompanyType.Company],
}: UserSignupFormProps) => {
  const api = useAPI();
  const form = useForm({
    initialValues,
    validationSchema: signupCheckValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (payload: SignupMinimalForm) => {
      try {
        await api.legacy.signupMinimal({
          companyRoles,
          ...payload,
        });
        // @ts-ignore
        analytics.track('signup:success');
        onSuccess(payload);
        trackGAConversion();
      } catch (e: any) {
        toast.error(e.message);
        // @ts-ignore
        analytics.track('signup:fail');
      }
    },
  });

  const email = useField('email', 'email', form).input;
  const firstName = useField('firstName', 'text', form).input;
  const lastName = useField('lastName', 'text', form).input;
  const company = useField('companyName', 'text', form).input;
  const invitationHash = useField('invitationHash', 'text', form).input;
  const acceptedTOS = useField('acceptedTOS', 'checkbox', form).input;

  useEffect(() => {
    if (acceptedTOS.value) {
      // @ts-ignore
      analytics.track('signup:accept-tos');
    }
  }, [acceptedTOS.value]);

  const {
    first_name: contactFirstName,
    last_name: contactLastName,
    email: contactEmail,
    company_name: companyName,
    invitation_hash: companyInvitationHash,
  }: {
    first_name?: string;
    last_name?: string;
    email?: string;
    company_name?: string;
    invitation_hash?: string;
  } = useQuery();
  const { subdomain } = getSubdomain();
  const inviteRedemption = useInvitationRedemption(companyInvitationHash);

  useEffect(() => {
    if (contactFirstName) {
      form.setFieldValue('firstName', contactFirstName);
    }
    if (contactLastName) {
      form.setFieldValue('lastName', contactLastName);
    }
    if (contactEmail) {
      form.setFieldValue('email', contactEmail);
    }
    if (companyName) {
      form.setFieldValue('companyName', companyName);
    }
    if (companyInvitationHash) {
      form.setFieldValue('invitationHash', companyInvitationHash);
    }
    if (subdomain) {
      form.setFieldValue('subdomain', subdomain);
    }
  }, [contactEmail]);

  return {
    form,
    inviteRedemption,
    fields: {
      email,
      firstName,
      lastName,
      company,
      invitationHash,
      acceptedTOS,
    },
  };
};

const CONVERSION_ID = `459218021`;

const trackGAConversion = () => {
  const image = new Image(1, 1);
  image.src = `http://www.googleadservices.com/pagead/conversion/${CONVERSION_ID}/?value=1&label=Purchase&script=0`;
};
