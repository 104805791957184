import React from 'react';
import { useSelector } from 'react-redux';
import { REVENUE_MODEL_V6_SELECT_OPTIONS } from '@hum/types';
import {
  Field,
  SelectInput,
  SelectInputField,
  TextInputField,
} from '@hum/legacy-ui';
import {
  OnboardingLayout,
  Button,
  Icon,
  Text,
  TextType,
  Topbar,
} from '@hum/ui-library';
import {
  COUNTRY_SELECT_OPTIONS_GROUPED,
  US_STATES_SELECT_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { IndustrySelect } from '@hum/icm-app/src/components/IndustrySelect';
import { useAboutYourBusiness } from './useAboutYourBusiness';
import { OutsideUS } from './OutsideUS';
import { useOnboardingPrefill } from '@hum/icm-app/src/hooks/useOnboardingPrefill';
import { AppState } from '@hum/icm-app/src/state';
import { useLocation } from 'react-router-dom';

export const AboutYourBusiness = () => {
  const { form, fields } = useAboutYourBusiness();
  const UNITED_STATES = 'US';
  const { state: { signUpEmail } = { signUpEmail: '' } } = useLocation<{
    signUpEmail?: string;
  }>();
  const session = useSelector((state: AppState) => state.session);
  const currentUser = session.data;
  const email = currentUser ? currentUser.email : signUpEmail;
  const { loading } = useOnboardingPrefill(form, email);

  const dataState = { loaded: loading };
  const title = 'Tell us about your business';
  const subtitle =
    'This will be shared with investors who are interested in companies like yours.';

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/logout" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <SelectInputField
                v3
                label="Headquarters country"
                inputAriaLabel="Headquarters country"
                {...fields.country}
                inputTestId="signup:country"
                options={COUNTRY_SELECT_OPTIONS_GROUPED}
              />
              {fields.country.value === UNITED_STATES && (
                <SelectInputField
                  {...fields.state}
                  v3
                  label="Headquarters state"
                  inputAriaLabel="Headquarters state"
                  inputTestId="signup:state"
                  options={US_STATES_SELECT_OPTIONS}
                />
              )}
              {fields.country.value !== UNITED_STATES &&
                fields.country.value !== '' && <OutsideUS />}

              <TextInputField
                {...fields.yearFounded}
                aria-label="Year Founded"
                label="Year Founded"
                inputAriaLabel="Year Founded"
                data-testid="signup:yearFounded"
              />
              <Field
                v3
                label="Industry"
                inputAriaLabel
                {...fields.selfReportedIndustry}
              >
                {(props) => (
                  <IndustrySelect
                    {...props}
                    loading={dataState.loaded}
                    data-testid="signup:industry"
                    placeholder=""
                    menuPlacement="top"
                  />
                )}
              </Field>
              <Field v3 label="Revenue Model" {...fields.revenueModel}>
                {(props) => (
                  <SelectInput
                    {...props}
                    aria-label="Revenue Model"
                    placeholder=""
                    options={REVENUE_MODEL_V6_SELECT_OPTIONS}
                    data-testid="signup:revenuemodel"
                    menuPlacement="top"
                  />
                )}
              </Field>
              <Button
                fullWidth
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                submit
                testId="link:login_page"
              >
                Next
              </Button>
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/logout" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
};
