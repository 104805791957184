import { fork } from 'redux-saga/effects';
import {
  handlePullAllCreditBoxesReports,
  handlePullCreditBoxReport,
  handleGetCreditBoxReports,
  handleDownloadCreditBoxReport,
} from './creditBoxReports';

export function* handleCreditBox() {
  yield fork(handlePullAllCreditBoxesReports);
  yield fork(handlePullCreditBoxReport);
  yield fork(handleDownloadCreditBoxReport);
  yield fork(handleGetCreditBoxReports);
}
