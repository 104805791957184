// Libraries
import React, { useMemo, useState } from 'react';
// Components
import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { SyndicationCompanyForm } from '@hum/icm-app/src/state';
import { SyndicationCompanyDetails, Application, Company } from '@hum/types';
import { apiSyndicationCompanyUpdated } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import { Box, getMultiSelectValues, Form } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import {
  UpdateSyndicationCompanyFormFields,
  validationSchema,
} from './FormFields';

const formatPayload = (syndicationCompany: SyndicationCompanyDetails) => {
  const application = normalize.syndicationCompanyDetail.out({
    ...syndicationCompany,
    ...getMultiSelectValues(syndicationCompany, ['investmentTypes']),
    investmentAmount:
      syndicationCompany.investmentAmount === null
        ? null
        : Number(syndicationCompany.investmentAmount),
    yearlyRevenueForSyndication:
      syndicationCompany.yearlyRevenueForSyndication === null ||
      syndicationCompany.yearlyRevenueForSyndication === undefined
        ? null
        : Number(syndicationCompany.yearlyRevenueForSyndication),
  });

  return application;
};
const transformValues = (application: Application, company: Company) => ({
  syndicationCompany: {
    investmentTypes: application.investmentTypes || [],
    investmentAmount: application.investmentAmount || null,
    yearlyRevenueForSyndication:
      application.yearlyRevenueForSyndication || null,
  },
  company: {
    businessTypeForSyndication:
      company.businessTypeForSyndication === null
        ? undefined
        : company.businessTypeForSyndication,
  },
});

export const CompanySyndicationForm = ({
  onClose,
  company,
}: {
  onClose: () => void;
  company: Company;
}) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAppStore();
  const { application } = company;

  const createSyndicationCompany = async (
    payload: SyndicationCompanyForm,
    resetForm: () => void
  ) => {
    try {
      setLoading(true);
      await Promise.all([
        backend.patch(
          `/capital_applications/${application.id}`,
          formatPayload(payload.syndicationCompany as any)
        ),
        backend.patch(
          `/companies/${company.id}`,
          normalize.company.out(payload.company)
        ),
      ]);

      toast.success('Updated syndication details!');
      dispatch(apiSyndicationCompanyUpdated({ companyId: company.id }));
      onClose();
      resetForm();
    } catch (error: any) {
      console.error(error);
      const message = error?.message || 'Failed to update syndication details.';
      toast.error(message);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const initialValues = useMemo(() => transformValues(application, company), [
    application,
    company,
  ]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: (payload: SyndicationCompanyForm, { resetForm }) =>
      createSyndicationCompany(payload, resetForm),
  });

  const error = form.status;

  const closeForm = () => {
    onClose();
    form.resetForm();
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <UpdateSyndicationCompanyFormFields form={form} />
      <div>{error}</div>
      <Box justifySpaceBetween>
        <Button
          type={ButtonType.SECONDARY}
          onClick={closeForm}
          disabled={loading || form.isSubmitting}
        >
          Cancel
        </Button>

        <Button
          submit
          disabled={loading || form.isSubmitting}
          loading={form.isSubmitting}
          testId="admin-page:main-area:syndication:company-information-modal:update-button"
        >
          Update
        </Button>
      </Box>
    </Form>
  );
};
