import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { SelectClearbitLocationInput } from '@hum/icm-app/src/components/SelectClearbitLocationInput';

import {
  REVENUE_MODEL_SELECT_OPTIONS,
  CompanyRevenueModel,
  CompanyBusinessType,
} from '@hum/types';
import { Field, InputMode, SelectInput, TextInputField } from '@hum/legacy-ui';
import {
  Button,
  Icon,
  OnboardingLayout,
  Text,
  TextType,
  Topbar,
} from '@hum/ui-library';
import { Routes } from '@hum/icm-app/src/state/routes';
import { useLogin } from '@hum/icm-app/src/pages/auth/hooks/useLogin';
import { Form } from '@hum/common/src/modules/form';
import { useOnboardingPrefill } from '@hum/icm-app/src/hooks/useOnboardingPrefill';
import { IndustrySelect } from '@hum/icm-app/src/components/IndustrySelect';
import { useAnalyticsEvent } from '@hum/api';

export const EnterYourEmail = ({
  fields,
  form,
  isWorkEmailValid,
}: {
  fields: SignupV4Payload;
  form: Form<SignupV4Payload>;
  isWorkEmailValid?: boolean;
}) => {
  const trackEvent = useAnalyticsEvent();

  useEffect(() => {
    trackEvent('company-visit-sign-up');
  }, []);

  const notAllowedEmailError =
    (!isWorkEmailValid &&
      form.touched.workEmail &&
      !form.errors.workEmail &&
      'Please use a valid work email address') ||
    undefined;

  return (
    <form data-testid="signup:work-email-form" onSubmit={form.handleSubmit}>
      <TextInputField
        label="Work email"
        // @ts-ignore
        {...fields.workEmail}
        error={notAllowedEmailError || form.errors.workEmail}
        inputTestId="signup:workemail"
      />
      <Button fullWidth onClick={form.handleSubmit}>
        Continue with email
      </Button>
    </form>
  );
};

export const AdditionalFields = ({
  fields,
  form,
}: {
  fields: SignupV4Payload;
  form: Form<SignupV4Payload>;
}) => {
  const flags = useFlags();
  const trackEvent = useAnalyticsEvent();
  const prefill = useOnboardingPrefill(form, form.values.workEmail);
  const dataState = { loaded: prefill.loading };

  useEffect(() => {
    trackEvent('visit:company-signup:additional-fields');
  }, []);

  return (
    <form data-testid="signup:company-info-form" onSubmit={form.handleSubmit}>
      <TextInputField
        label="Legal business name"
        inputTestId="signup:businessname"
        // @ts-ignore
        {...fields.legalBusinessName}
      />
      <TextInputField
        label="Website"
        // @ts-ignore
        {...fields.website}
        inputTestId="signup:website"
      />
      <Field
        v3
        label="Location"
        // @ts-ignore
        {...fields.location}
        data-testid="signup:location"
      >
        {(props) => (
          <SelectClearbitLocationInput
            {...props}
            loading={dataState.loaded}
            placeholder=""
          />
        )}
      </Field>
      {/* @ts-ignore */}
      <Field v3 label="Industry" {...fields.industry}>
        {(props) => (
          <IndustrySelect
            {...props}
            loading={dataState.loaded}
            data-testid="signup:industry"
            placeholder=""
            menuPlacement="top"
          />
        )}
      </Field>
      {/* FF: SignupShowYearFounded - Remove once the flag is removed */}
      {flags.enabled('signup-show-year-founded') && (
        <TextInputField
          // @ts-ignore
          {...fields.yearFounded}
          label="Year founded"
          aria-label="Year Founded"
          data-testid="signup:yearFounded"
        />
      )}
      {/* @ts-ignore */}
      <Field v3 label="Revenue Model" {...fields.revenueModel}>
        {(props) => (
          <SelectInput
            {...props}
            aria-label="Revenue Model"
            placeholder=""
            options={REVENUE_MODEL_SELECT_OPTIONS}
            data-testid="signup:revenuemodel"
          />
        )}
      </Field>
      <Button
        fullWidth
        onClick={form.handleSubmit}
        testId="signup:createaccount"
      >
        Create your account
      </Button>
    </form>
  );
};

type SignupV4Payload = {
  workEmail: string;
  acceptedCommunications: boolean;
  legalBusinessName: string;
  website: string;
  location: string;
  industry: CompanyBusinessType;
  yearFounded: string;
  revenueModel: CompanyRevenueModel;
};

export const SignIn = ({ isWhitelabeled }: { isWhitelabeled: boolean }) => {
  const { form, fields } = useLogin();
  const trackEvent = useAnalyticsEvent();

  useEffect(() => {
    trackEvent('pageview:login-page');
  }, []);

  return (
    <>
      <Helmet title={`Login page for Hum's Intelligent Capital Market`} />
      <Topbar
        logo={<Icon.Hum />}
        title="Login"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header
            title={
              isWhitelabeled
                ? 'Sign in with Hum'
                : "Let's get your business funded"
            }
          />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              New to Hum? <br />
              <a href={Routes.SIGNUP} className="ui-link text-bodyMicoSize">
                Create a Company account
              </a>
              <br />
              <a
                href={Routes.SIGNUP_INVESTOR}
                className="ui-link text-bodyMicoSize"
              >
                Create an Investor account
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <TextInputField
                label="Email"
                inputTestId="login:email"
                {...fields.email}
              />
              <TextInputField
                inputMode={InputMode.PASSWORD}
                label="Password"
                inputTestId="login:password"
                {...fields.password}
              />
              <a
                href={Routes.RECOVER}
                data-testid="link:recover_account"
                className="block mb-4 ui-link"
              >
                Forgot your password?
              </a>
              <Button
                submit
                fullWidth
                loading={form.isSubmitting}
                testId="link:login_page"
              >
                Sign in with email
              </Button>
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                New to Hum? <br />
                <a href={Routes.SIGNUP} className="ui-link text-bodyMicoSize">
                  Create a Company account
                </a>
                <br />
                <a
                  href={Routes.SIGNUP_INVESTOR}
                  className="ui-link text-bodyMicoSize"
                >
                  Create an Investor account
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
};
