import React from 'react';
import * as styles from '@hum/icm-app/src/pages/auth/InvestorSignup/index.pc';
import { InvestorSetPasswordForm } from './investor-set-password-form';
import { useQuery } from '../../../hooks/useQuery';
import { useSetPassword } from '../hooks/useSetPassword';
import { Spinner } from '@hum/ui-library';
export const PortfolioInvestorSetPassword = () => {
  const {
    company = '',
    email = '',
    name = '',
  }: {
    company?: string;
    email?: string;
    name?: string;
  } = useQuery();
  const {
    form,
    fields,
    tosIsAccepted,
    tosIsAcceptedLoaded,
    reviewedCommunicationsOptIn,
  } = useSetPassword();

  if (!tosIsAcceptedLoaded) {
    return <Spinner fullScreen />;
  }
  return (
    <>
      <styles.InvestorSetPassword
        firstStep
        subtitle={`${name}, set your password below to activate your Hum Capital account. Your registered email is ${email}`}
        headerTitle={'Sign up'}
        title={'Create a password'}
      >
        <InvestorSetPasswordForm
          companyName={company}
          fields={fields}
          form={form}
          tosIsAccepted={tosIsAccepted}
          reviewedCommunicationsOptIn={reviewedCommunicationsOptIn}
        />
      </styles.InvestorSetPassword>
    </>
  );
};
