import React from 'react';
import { Route, Switch } from 'react-router';

import { PortfolioInvestorSetPassword } from '@hum/icm-app/src/pages/auth/regular/set-password-portfolio-monitoring';
import { SyndicationInvestorSetPassword } from '@hum/icm-app/src/pages/auth/regular/set-password-syndication';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled';
import { Routes } from '@hum/icm-app/src/state';

import { InvestorSignupPage } from './InvestorSignup';
import { Recover, SetPassword, SignIn, SignUpCompleted } from './v4/index';
import {
  SignUp as NewSignUp,
  AboutYourBusiness,
  FundraisingHistory,
  ShareYourGoals,
  VerifyEmail,
  SignUpComplete,
  ReviewAndConfirm,
} from './choose-your-adventure';

export const AuthPage = () => {
  const isWhitelabeled = useIsWhitelabeled();

  return (
    <Switch>
      <Route path={Routes.SIGNUP_SYNDICATION_INVESTOR}>
        <SyndicationInvestorSetPassword />
      </Route>
      <Route path={Routes.SIGNUP_PORTFOLIO_INVESTOR}>
        <PortfolioInvestorSetPassword />
      </Route>
      <Route path={[Routes.SIGNUP_INVESTOR]}>
        <InvestorSignupPage />
      </Route>
      <Route path={Routes.SIGNUP_V4_COMPLETED}>
        <SignUpCompleted isWhitelabeled={isWhitelabeled} />
      </Route>
      <Route path={Routes.LOGIN}>
        <SignIn isWhitelabeled={isWhitelabeled} />
      </Route>
      <Route path={Routes.RECOVER}>
        <Recover isWhitelabeled={isWhitelabeled} />
      </Route>
      <Route path={Routes.SET_PASSWORD}>
        <SetPassword isWhitelabeled={isWhitelabeled} />
      </Route>
      <Route path={Routes.VERIFY_EMAIL}>
        <VerifyEmail />
      </Route>

      <Route path={Routes.REVIEW_AND_CONFIRM}>
        <ReviewAndConfirm />
      </Route>
      <Route path={Routes.ABOUT_YOUR_BUSINESS}>
        <AboutYourBusiness />
      </Route>
      <Route path={Routes.SHARE_YOUR_GOALS}>
        <ShareYourGoals />
      </Route>
      <Route path={Routes.FUNDRAISING_HISTORY}>
        <FundraisingHistory />
      </Route>
      <Route path={Routes.COMPLETE_SIGNUP}>
        <SignUpComplete />
      </Route>
      <Route path={Routes.SIGNUP}>
        <NewSignUp isWhitelabeled={isWhitelabeled} />
      </Route>
    </Switch>
  );
};
