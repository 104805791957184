import React from 'react';
import { Company } from '@hum/types';
import { EMPTY_ARRAY } from '@hum/icm-app/src/state';
import { Empty } from '@hum/ui-library';
import { useCompanyAnalyses } from '@hum/icm-app/src/hooks/useCompanyAnalyses';
import { formatDateFromNow } from '@hum/icm-app/src/utils';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  companyAnalysisSelected,
  companyAnalysisFrozen,
} from '@hum/icm-app/src/actions';

// TODO: use @hum/design-system instead
import {
  FieldStyles,
  StyledToggle,
  Table,
  TableCellConfig,
} from '@hum/legacy-ui';
import * as styles from './styles.pc';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getCompanyAnalyses, useApiQuery } from '@hum/api';

type CompanyAnalysesSelectorProps = {
  company: Company;
};

export const CompanyAnalysesSelector = ({
  company,
}: CompanyAnalysesSelectorProps) => {
  const flags = useFlags();
  const {
    dispatch,
    state: { currentCompany, selectedCompanyAnalysisId },
  } = useAppStore();
  const {
    data: queryCompanyAnalyses = EMPTY_ARRAY,
    isLoading: isLoadingCompanyAnalyses,
  } = useApiQuery(getCompanyAnalyses, {
    params: { companyId: company.id },
    enabled: flags.enabled('use-react-query'),
  });
  const {
    loaded: sagasCompanyAnalysesLoaded,
    data: sagasCompanyAnalyses = EMPTY_ARRAY,
  } = useCompanyAnalyses(company.id);

  const companyAnalyses = flags.enabled('use-react-query')
    ? queryCompanyAnalyses
    : sagasCompanyAnalyses;
  const loaded = flags.enabled('use-react-query')
    ? !isLoadingCompanyAnalyses
    : sagasCompanyAnalysesLoaded;

  if (!loaded) {
    return <Empty message="Loading" />;
  }

  if (!companyAnalyses.length) {
    return <Empty message="No company analyses have been generated yet." />;
  }

  const cellConfig: Record<string, TableCellConfig> = {
    frozen: {
      label: '',
      renderer: ({ item, propertyName }) => (
        <>{item[propertyName] && <styles.Frozen />}</>
      ),
    },
    dateRun: {
      label: 'Date Run',
      renderer: ({ item, propertyName }) => (
        <>{formatDateFromNow(item[propertyName])}</>
      ),
    },
    status: {
      label: 'Status',
    },
    numberAnalyticsGenerated: {
      label: 'Analytics',
    },
    numberOfWarnings: {
      label: 'Warnings',
    },
    airflowUrlString: {
      label: 'Run url',
      renderer: ({ item, propertyName }) =>
        item[propertyName] ? <a href={item[propertyName]}>link</a> : <p>--</p>,
    },
  };

  const itemKey = 'value';

  const tableData = companyAnalyses.map((companyAnalysis) => {
    const {
      createdAt,
      status,
      chartCount: numberAnalyticsGenerated,
      warningCount: numberOfWarnings,
      airflowUrl: airflowUrlString,
    } = companyAnalysis;

    return {
      frozen:
        currentCompany.data?.frozenCompanyAnalysisId === companyAnalysis.id,
      dateRun: createdAt,
      value: companyAnalysis.id,
      status,
      numberAnalyticsGenerated,
      numberOfWarnings,
      airflowUrlString,
    };
  });

  const onRowClick = (value: number) => {
    dispatch(
      companyAnalysisSelected({
        companyId: company.id,
        selectedCompanyAnalysisId: value,
      })
    );
  };

  const toggled =
    currentCompany.data?.frozenCompanyAnalysisId === selectedCompanyAnalysisId;

  const onToggleClicked = () => {
    dispatch(
      companyAnalysisFrozen({
        companyId: company.id,
        frozenCompanyAnalysisId: toggled
          ? undefined
          : selectedCompanyAnalysisId,
      })
    );
  };

  let toggle;
  if (toggled) {
    toggle = <StyledToggle on onClick={onToggleClicked} />;
  } else {
    toggle = <StyledToggle onClick={onToggleClicked} />;
  }

  return (
    <>
      <FieldStyles v3 toggle companyAnalyses label="Freeze Analytics">
        {toggle}
      </FieldStyles>
      <Table
        style={{ '--scrollbar-height': 'calc(4 * 42px)' }}
        companyAnalyses
        cellConfig={cellConfig}
        data={tableData}
        itemKey={itemKey}
        onRowClick={onRowClick}
        selectedRow={selectedCompanyAnalysisId}
        dontSortByDefault
        scrollToSelected
      />
    </>
  );
};
