import React from 'react';
import * as styles from '@hum/icm-app/src/pages/auth/InvestorSignup/index.pc';
import { InvestorSetPasswordForm } from './investor-set-password-form';
import { useQuery } from '../../../hooks/useQuery';
import { useSetPassword } from '../hooks/useSetPassword';
import { Spinner } from '@hum/ui-library';

export const SyndicationInvestorSetPassword = () => {
  const {
    company = '',
    email = '',
  }: {
    company?: string;
    email?: string;
  } = useQuery();
  const {
    form,
    fields,
    tosIsAccepted,
    tosIsAcceptedLoaded,
    reviewedCommunicationsOptIn,
  } = useSetPassword();
  if (!tosIsAcceptedLoaded) {
    return <Spinner fullScreen />;
  }
  return (
    <>
      <styles.InvestorSetPassword
        headerTitle="Step 1 of 2"
        showSteps
        firstStep
        subtitle={`Your registered email is ${email}`}
        title="Get started by creating a password"
      >
        <InvestorSetPasswordForm
          form={form}
          companyName={company}
          fields={fields}
          tosIsAccepted={tosIsAccepted}
          reviewedCommunicationsOptIn={reviewedCommunicationsOptIn}
        />
      </styles.InvestorSetPassword>
    </>
  );
};
