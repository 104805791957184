import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import { Routes } from '@hum/icm-app/src/state/routes';
import { VerifyEmail } from '@hum/icm-app/src/pages/onboarding-v4/components/VerifyEmail/styles.pc';
import {
  OnboardingContent,
  OnboardingLayout,
} from '@hum/icm-app/src/pages/onboarding-v4/styles/layout.pc';

export const SignUpCompleted = ({}: { isWhitelabeled: boolean }) => {
  const {
    state: { signUpEmail } = { signUpEmail: 'your inbox' },
  } = useLocation<{ signUpEmail?: string }>();

  return (
    <OnboardingLayout>
      <Helmet title="Verify your email" />
      <OnboardingContent centered>
        <VerifyEmail
          email={signUpEmail}
          returnToLogin={<Link to={Routes.SIGNUP}>Return to sign up</Link>}
        />
      </OnboardingContent>
    </OnboardingLayout>
  );
};
