import React from 'react';
import * as Yup from 'yup';
import { Form, useField } from '@hum/common/src/modules/form';
import {
  BUSINESS_TYPE_SELECT_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { SelectInputField, TextInputField, InputMode } from '@hum/legacy-ui';
import { SyndicationCompanyForm } from '@hum/icm-app/src/state';

export const validationSchema = Yup.object().shape({
  syndicationCompany: Yup.object().shape({
    investmentTypes: Yup.array().nullable(),
    investmentAmount: Yup.number()
      .integer('Use whole numbers without notations like 1K, 1M, 1B')
      .typeError('Please enter a whole number.')
      .min(1, 'Please enter an amount greater than $0.')
      .max(
        999999999,
        'Please enter an amount less than or equal to $999,999,999.'
      )
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    yearlyRevenueForSyndication: Yup.string().nullable(),
  }),
  company: Yup.object().shape({
    businessTypeForSyndication: Yup.string().nullable(),
  }),
});

export const UpdateSyndicationCompanyFormFields = ({
  form,
}: {
  form: Form<SyndicationCompanyForm>;
}) => {
  return (
    <>
      <SelectInputField
        aria-label="Investment type/stage"
        label="Investment type/stage"
        placeholder="Select all that apply"
        options={SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS}
        {...useField('syndicationCompany.investmentTypes', 'select', form)
          .input}
        isClearable
        isMulti
      />
      <TextInputField
        inputTestId="company-syndication:investment-amount"
        label="Looking for investment amount"
        inputMode={InputMode.DOLLAR}
        {...useField('syndicationCompany.investmentAmount', 'text', form).input}
      />
      <SelectInputField
        label="Business Type"
        {...useField('company.businessTypeForSyndication', 'select', form)
          .input}
        inputAriaLabel="Business Type for Syndication"
        testId="admin-page:main-area:syndication:company-information-modal:business-type-for-syndication"
        options={BUSINESS_TYPE_SELECT_OPTIONS}
        secondary
        isClearable
      />
      <TextInputField
        inputTestId="admin-page:main-area:syndication:company-information-modal:yearly-revenue-for-syndication"
        label="Yearly revenue for syndication"
        inputMode={InputMode.DOLLAR}
        {...useField(
          'syndicationCompany.yearlyRevenueForSyndication',
          'text',
          form
        ).input}
      />
    </>
  );
};
