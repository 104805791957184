import React from 'react';
import { USE_OF_FUNDS_OPTIONS, CAPITAL_DEPLOYMENT_OPTIONS } from '@hum/types';
import { InputMode, SelectInputField, TextInputField } from '@hum/legacy-ui';
import {
  Button,
  Icon,
  OnboardingLayout,
  Topbar,
  Text,
  TextType,
} from '@hum/ui-library';
import {
  FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS,
  FUNDING_TYPE_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { useShareYourGoals } from './useShareYourGoals';

export function ShareYourGoals() {
  const { form, fields } = useShareYourGoals();
  const title = 'Share your goals';
  const subtitle =
    'Help us understand your fundraising needs so that we can get your business humming with the right capital.';

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/logout" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <TextInputField
                {...fields.investmentAmount}
                label="How much capital are you looking to raise?"
                inputAriaLabel="How much capital are you looking to raise?"
                data-testid="signup:target-capital-raise"
                inputMode={InputMode.DOLLAR}
              />
              <SelectInputField
                {...fields.fundingType}
                label="Funding type"
                inputAriaLabel="Funding type"
                data-testid="signup:funding-type"
                options={FUNDING_TYPE_OPTIONS}
              />
              <SelectInputField
                {...fields.fundraisingTimeFrame}
                label="Fundraising timeframe"
                inputAriaLabel="Fundraising timeframe"
                data-testid="signup:funding-timeframe"
                options={FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS}
                menuPlacement="top"
              />
              <SelectInputField
                {...fields.useOfFunds}
                label="What do you plan to use the funds for?"
                inputAriaLabel="What do you plan to use the funds for?"
                inputTestId="signup:use-of-funds"
                options={USE_OF_FUNDS_OPTIONS}
                menuPlacement="top"
              />
              <SelectInputField
                {...fields.capitalDeployment}
                label="How would you like to receive the funds?"
                inputAriaLabel="How would you like to receive the funds?"
                inputTestId="signup:capital-deployment"
                options={CAPITAL_DEPLOYMENT_OPTIONS}
                menuPlacement="top"
              />
              <Button
                fullWidth
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                testId="link:about-you"
                submit
              >
                Next
              </Button>
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/logout" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
}
