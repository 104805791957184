import { toast } from '@hum/common/src/modules/toast';
import { useState } from 'react';
import * as Yup from 'yup';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useDispatch } from 'react-redux';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { recoverAccount, useApiMutation } from '@hum/api';

const initialValues = { email: '' };
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
});

export const useRecover: any = () => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const [recoverReady, setRecoverReady] = useState(false);
  const api = useAPI();
  const { mutate: recoverUserAccount } = useApiMutation(recoverAccount);
  const sagaRecoverAccount = async (payload: any) => {
    try {
      await api.auth.recoverAccount(dispatch)(payload);
      toast.success(
        'An email to your inbox has been sent with instructions to recover your account.'
      );
      setRecoverReady(true);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const form = useForm({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (payload) => {
      flags.enabled('use-react-query')
        ? recoverUserAccount(payload)
        : sagaRecoverAccount(payload);
    },
  });

  const email = useField('email', 'text', form);

  const loading = form.isSubmitting;

  return {
    form,
    loading,
    recoverReady,
    setRecoverReady,
    fields: {
      email,
    },
  };
};
