import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { toast } from '@hum/common/src/modules/toast';
import { Documents } from '../../DocumentManagement/Documents';
import {
  AnalyticsStatus,
  Company,
  CompanyFileDocumentType,
  CompanyFile,
} from '@hum/types';
import { getCSVColumns } from '@hum/icm-app/src/utils/csv';
import { client as backend } from '@hum/common/src/api/client';
import { InfoSection } from '@hum/ui-library';
import { CompanyAnalysesSelector } from './CompanyAnalysesSelector';
import { CompanyAnalysisWarnings } from './CompanyAnalysisWarnings';
import { Pill } from '@hum/legacy-ui';
import * as styles from './styles.pc';

type Props = {
  company: Company;
};

const MAP_PROPERTY_NAMES = [
  'transaction_date',
  'transaction_id',
  'transaction_amount',
  'transaction_label',
  'payor_id',
  'payor_type',
  'payee_id',
  'payee_type',
  'currency',
];

const initialValues = {
  transaction_date: '',
  transaction_id: '',
  transaction_amount: '',
  payor_id: '',
};

const validationSchema = Yup.object().shape({
  transaction_date: Yup.string().required('Required'),
  transaction_id: Yup.string().required('Required'),
  transaction_amount: Yup.string().required('Required'),
  transaction_label: Yup.string(),
  payor_id: Yup.string().required('Required'),
  payor_type: Yup.string(),
  payee_id: Yup.string(),
  payee_type: Yup.string(),
  currency: Yup.string(),
});

const usePipelineRun = (companyId: number) => {
  const [pipelineRunURL, setPipelineRunURL] = useState<string | null>();

  const startPipelineRun = async () => {
    const resultUrl = await backend
      .post(
        `/companies/${companyId}/company_analysis`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        if (response['id']) {
          toast.success('Pipeline run started!');
          return response['id'];
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to start pipeline run.');
      });
    setPipelineRunURL(resultUrl);
  };

  return {
    // state
    pipelineRunURL,
    // handlers
    startPipelineRun,
  };
};

export const DataTab: React.FC<Props> = ({ company }) => {
  const fileManager = useFileManager({
    companyId: company.id,
    filter: (v: CompanyFile) => {
      return Boolean(v.meta?.uploadedByAdmin) === false;
    },
  });

  const dataMapInfo = useMemo(() => {
    return {
      propertyNames: MAP_PROPERTY_NAMES,
      initialValues,
      validationSchema,
      getOptions(url: string) {
        return getCSVColumns(url);
      },
    };
  }, []);

  const { startPipelineRun: startProcessing } = usePipelineRun(company.id);

  const analyticsStatus = company.analyticsStatus;
  const positiveAnalyticsStatus = [
    AnalyticsStatus.Healthy,
    AnalyticsStatus.Locked,
  ];
  const negativeAnalyticsStatus = [
    AnalyticsStatus.Critical,
    AnalyticsStatus.NeedsLabeling,
    AnalyticsStatus.AnalyticsRegression,
    AnalyticsStatus.AnalyticsStale,
    AnalyticsStatus.Recovering,
    AnalyticsStatus.DataStale,
  ];

  const ANALYTICS_STATUS_DESCRIPTIONS = {
    [AnalyticsStatus.Healthy]:
      'Analytics have been generated in the last 7 days, are up to date, and there were no unexpected errors',
    [AnalyticsStatus.DataStale]:
      'Analytics exist but no new data has been uploaded (file) or pulled (connector) for more than 7 days',
    [AnalyticsStatus.AnalyticsStale]:
      'Analytics exist but new data has been uploaded (file) or pulled (connector), or labeling changed since the last analytics pipeline run',
    [AnalyticsStatus.AnalyticsRegression]:
      'The latest analytics pipeline run did not generate a chart that previously existed',
    [AnalyticsStatus.Pending]: 'Analytics have not yet be run for this company',
    [AnalyticsStatus.NeedsLabeling]:
      "Analytics have not yet be run for this company because it hasn't been labeled",
    [AnalyticsStatus.Critical]:
      'The last analytics run had an unexpected error',
    [AnalyticsStatus.Recovering]:
      'The last analytics run was successful, but the previous analytics pipeline failed',
  };

  return (
    <>
      <InfoSection title="Analytics Status">
        <Pill
          positive={positiveAnalyticsStatus.includes(analyticsStatus)}
          negative={negativeAnalyticsStatus.includes(analyticsStatus)}
          title={ANALYTICS_STATUS_DESCRIPTIONS[analyticsStatus] || 'N/A'}
        >
          {analyticsStatus}
        </Pill>
      </InfoSection>

      <InfoSection title="Company Analyses">
        <CompanyAnalysesSelector company={company} />
      </InfoSection>

      <InfoSection title="Company Analysis Warnings">
        <CompanyAnalysisWarnings company={company} />
      </InfoSection>

      <InfoSection
        title="Company Charts"
        primaryAction={{
          text: 'Generate Charts',
          onClick: startProcessing,
        }}
      />

      <InfoSection title="Cleaned Files">
        <styles.CleanedFiles>
          <Documents
            title="Cleaned income statement"
            documentType={CompanyFileDocumentType.CleanedIncomeStatement}
            fileManager={fileManager}
            company={company}
            canDelete
          />
          <Documents
            title="Cleaned balance sheet"
            documentType={CompanyFileDocumentType.CleanedBalanceSheet}
            company={company}
            fileManager={fileManager}
            canDelete
          />
          <Documents
            title="Cleaned customer transactions"
            documentType={CompanyFileDocumentType.CleanedCustomerTransactions}
            company={company}
            fileManager={fileManager}
            dataMapInfo={dataMapInfo}
            canDelete
          />
        </styles.CleanedFiles>
      </InfoSection>
    </>
  );
};
