import React from 'react';
import {
  USE_OF_FUNDS_OPTIONS,
  REVENUE_MODEL_V6_SELECT_OPTIONS,
  CAPITAL_DEPLOYMENT_OPTIONS,
} from '@hum/types';
import {
  Detail,
  Separator,
  Field,
  Fieldset,
  InputMode,
  SelectInputField,
  TextInputField,
} from '@hum/legacy-ui';
import {
  Button,
  Text,
  TextType,
  OnboardingLayout,
  Icon,
  Topbar,
} from '@hum/ui-library';
import { IndustrySelect } from '@hum/icm-app/src/components/IndustrySelect';
import {
  FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS,
  FUNDING_TYPE_OPTIONS,
  LAST_FUNDING_ROUND_OPTIONS,
  EQUITY_RAISED_SELECT_OPTIONS,
  US_STATES_SELECT_OPTIONS,
  COUNTRY_SELECT_OPTIONS_GROUPED,
} from '@hum/icm-app/src/components/constants';
import { useReviewAndConfirm } from './useReviewAndConfirm';
import { OutsideUS } from '../AboutYourBusiness/OutsideUS';

export function ReviewAndConfirm() {
  const { form, fields, disableAdditionalFields } = useReviewAndConfirm();
  const UNITED_STATES = 'US';

  const title = 'Review and confirm';
  const subtitle =
    'Review the information entered by our team before continuing. This data will be used to find and curate funding opportunities that match your profile.';

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/logout" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              <Fieldset label="Profile information" squished>
                <Detail
                  horizontal
                  padded
                  mobile
                  title="Legal business name"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      placeholder="Company name"
                      {...fields.legalBusinessName}
                      inputTestId="signup:businessname"
                      disabled
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Email"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      placeholder="Work email"
                      inputTestId="signup:email"
                      {...fields.workEmail}
                      disabled
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  wideDescription
                  marginBottomLarge
                  title="First name"
                  description={
                    <TextInputField
                      placeholder="First name"
                      inputTestId="signup:firstname"
                      {...fields.firstName}
                      disabled
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Last name"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      placeholder="Last name"
                      inputTestId="signup:lastname"
                      {...fields.lastName}
                      disabled
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  wideDescription
                  title="Job title"
                  marginBottomLarge
                  description={
                    <TextInputField
                      inputTestId="signup:companyrole"
                      placeholder="Job title"
                      {...fields.companyRole}
                    />
                  }
                />
              </Fieldset>
              <Separator />
              <Fieldset squished label="Business details">
                <Text type={TextType.BODY_S} className="text-subduedTextColor">
                  <span className="text-red-500">*</span> indicates requried
                  field
                </Text>
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Headquarters country"
                  wideDescription
                  marginBottomLarge
                  description={
                    <>
                      <SelectInputField
                        v3
                        {...fields.country}
                        data-testid="signup:country"
                        options={COUNTRY_SELECT_OPTIONS_GROUPED}
                      />
                      {fields.country.value !== UNITED_STATES &&
                        fields.country.value !== '' && (
                          <div className="my-4">
                            <OutsideUS />
                          </div>
                        )}
                    </>
                  }
                />
                {fields.country.value === UNITED_STATES && (
                  <Detail
                    horizontal
                    mobile
                    padded
                    title="Headquarters state"
                    wideDescription
                    marginBottomLarge
                    description={
                      <SelectInputField
                        v3
                        {...fields.stateLocation}
                        data-testid="signup:state"
                        options={US_STATES_SELECT_OPTIONS}
                        disabled={fields.country.value !== 'US' ? true : false}
                      />
                    }
                  />
                )}

                <Detail
                  horizontal
                  mobile
                  padded
                  title="Website"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      placeholder="website.com"
                      {...fields.website}
                      inputTestId="signup:website"
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Year founded"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      {...fields.yearFounded}
                      aria-label="Year Founded"
                      placeholder="YYYY"
                      data-testid="signup:yearFounded"
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Industry"
                  wideDescription
                  marginBottomLarge
                  description={
                    <Field v3 {...fields.selfReportedIndustry}>
                      {(props) => (
                        <IndustrySelect
                          {...props}
                          data-testid="signup:industry"
                          placeholder="Select an option"
                          showPlaceholder
                        />
                      )}
                    </Field>
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Revenue model"
                  wideDescription
                  description={
                    <SelectInputField
                      placeholder="Select an option"
                      {...fields.revenueModel}
                      aria-label="Revenue Model"
                      data-testid="signup:revenuemodel"
                      options={REVENUE_MODEL_V6_SELECT_OPTIONS}
                    />
                  }
                />
              </Fieldset>
              <Separator />
              <Fieldset squished label="Funding goals">
                <Text type={TextType.BODY_S} className="text-subduedTextColor">
                  <span className="text-red-500">*</span> indicates requried
                  field
                </Text>
                <Detail
                  horizontal
                  padded
                  mobile
                  title="Target capital raise"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      {...fields.investmentAmount}
                      inputTestId="signup:target-capital-raise"
                      label="Target capital raise"
                      inputMode={InputMode.DOLLAR}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Funding type"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      placeholder="Select an option"
                      {...fields.fundingType}
                      inputAriaLabel="Funding type"
                      data-testid="signup:funding-type"
                      options={FUNDING_TYPE_OPTIONS}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Fundraising timeframe"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      {...fields.fundraisingTimeFrame}
                      placeholder="Select an option"
                      inputAriaLabel="Fundraising timeframe"
                      data-testid="signup:funding-timeframe"
                      options={FUNDRAISING_TIME_FRAME_MONTHS_SELECT_OPTIONS}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="What do you plan to use the funds for?"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      {...fields.useOfFunds}
                      placeholder="Select an option"
                      inputTestId="signup:use-of-funds"
                      options={USE_OF_FUNDS_OPTIONS}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="How would you like to receive the funds?"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      {...fields.capitalDeployment}
                      placeholder="Select an option"
                      inputTestId="signup:capital-deployment"
                      options={CAPITAL_DEPLOYMENT_OPTIONS}
                    />
                  }
                />
              </Fieldset>
              <Separator />
              <Fieldset squished label="Funding history">
                <Text type={TextType.BODY_S} className="text-subduedTextColor">
                  <span className="text-red-500">*</span> indicates requried
                  field
                </Text>
                <Detail
                  horizontal
                  padded
                  mobile
                  title="Last funding round"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      {...fields.lastStageOfFunding}
                      placeholder="Select an option"
                      inputAriaLabel="Most recent financing round"
                      data-testid="signup:last-stage-of-funding"
                      options={LAST_FUNDING_ROUND_OPTIONS}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Date of last raise (optional)"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      {...fields.dateOfLastRaise}
                      inputMode={InputMode.MONTH_YEAR_DATE}
                      placeholder="MM/YYYY"
                      infoText="MM/YYYY"
                      inputAriaLabel="Date of last raise"
                      inputTestId="signup:date-of-last-raise"
                      disabled={disableAdditionalFields}
                    />
                  }
                />
                <Detail
                  horizontal
                  padded
                  mobile
                  title="Total equity raised to date (optional)"
                  wideDescription
                  marginBottomLarge
                  description={
                    <SelectInputField
                      {...fields.totalEquityRaised}
                      isClearable
                      placeholder="Select an option"
                      inputAriaLabel="Total equity raised to date (optional)"
                      data-testid="signup:total-equity-raised"
                      options={EQUITY_RAISED_SELECT_OPTIONS}
                      disabled={disableAdditionalFields}
                    />
                  }
                />
                <Detail
                  horizontal
                  mobile
                  padded
                  title="Last valuation (optional)"
                  wideDescription
                  marginBottomLarge
                  description={
                    <TextInputField
                      {...fields.lastValuation}
                      placeholder="Last valuation"
                      inputMode={InputMode.DOLLAR}
                      inputTestId="signup:last-valuation"
                      inputAriaLabel="Last valuation (optional)"
                      disabled={disableAdditionalFields}
                    />
                  }
                />
              </Fieldset>

              <Button
                fullWidth
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                testId="link:about-you"
                submit
              >
                Next
              </Button>
              {form.isValid}
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/logout" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
}
