import React from 'react';
import { Company } from '@hum/types';
import { EMPTY_ARRAY } from '@hum/icm-app/src/state';
import { Empty } from '@hum/ui-library';
import { useCompanyAnalysisWarnings } from '@hum/icm-app/src/hooks/useCompanyAnalyses';
import { Table, TableCellConfig } from '@hum/legacy-ui';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { getCompanyAnalysisWarnings, useApiQuery } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export const CompanyAnalysisWarnings = ({ company }: { company: Company }) => {
  const flags = useFlags();

  const {
    state: { selectedCompanyAnalysisId },
  } = useAppStore();

  const {
    loaded: sagasCompanyAnalysisWarningsLoaded,
    data: sagasCompanyAnalysisWarnings = EMPTY_ARRAY,
  } = useCompanyAnalysisWarnings(company.id, selectedCompanyAnalysisId);
  const {
    data: queryCompanyAnalysisWarnings = EMPTY_ARRAY,
    isLoading: isLoadingCompanyAnalysisWarnings,
  } = useApiQuery(getCompanyAnalysisWarnings, {
    params: {
      companyId: company.id,
      companyAnalysisId: selectedCompanyAnalysisId,
    },
    enabled: flags.enabled('use-react-query') && !!selectedCompanyAnalysisId,
  });

  const data = flags.enabled('use-react-query')
    ? queryCompanyAnalysisWarnings
    : sagasCompanyAnalysisWarnings;

  const loaded = flags.enabled('use-react-query')
    ? !isLoadingCompanyAnalysisWarnings
    : sagasCompanyAnalysisWarningsLoaded;

  if (!loaded) {
    return <Empty message="Loading" />;
  }

  if (!data.length) {
    return <Empty message="No warnings have been generated." />;
  }

  const cellConfig: Record<string, TableCellConfig> = {
    message: {
      label: 'Message',
    },
    detailedMessage: {
      label: 'Details',
    },
  };

  const itemKey = 'value';

  const tableData = data.map((warning) => {
    const { id, message, detailedMessage } = warning;
    return {
      value: id,
      message,
      detailedMessage,
    };
  });

  return (
    <Table
      style={{ '--scrollbar-height': 'calc(4 * 42px)' }}
      companyAnalyses
      cellConfig={cellConfig}
      data={tableData}
      itemKey={itemKey}
      dontSortByDefault
    />
  );
};
