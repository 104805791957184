import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { TextInputField } from '@hum/legacy-ui';
import {
  Button,
  Text,
  TextType,
  OnboardingLayout,
  Icon,
  Topbar,
  Splash,
  ButtonType,
} from '@hum/ui-library';
import { Routes } from '@hum/icm-app/src/state/routes';
import { useRecover } from '@hum/icm-app/src/pages/auth/hooks/useRecover';
import { Transition } from '@headlessui/react';

export const Recover = ({}: { isWhitelabeled: boolean }) => {
  const { form, fields } = useRecover();
  const history = useHistory();
  const hasSubmitted = !!form.submitCount && form.isValid;
  const signUpEmail = fields.email.input.value;
  const [animation, setAnimation] = React.useState(false);

  useEffect(() => {
    setAnimation(hasSubmitted);
  }, [hasSubmitted]);

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      {hasSubmitted ? (
        <div className="flex h-screen">
          <div className="flex flex-col items-center justify-around w-full">
            <Splash
              testId="signup-complete"
              graphic={
                <Transition
                  show={animation}
                  enter="transform transition duration-700"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                >
                  <Icon.VerifyEmail className="max-w-[200px] mx-auto" />
                </Transition>
              }
              title="Verify your email"
              subtitle={`To make sure you’re you, we’ve sent a verification email to ${signUpEmail}. Click the link inside to continue.`}
            >
              <Button
                type={ButtonType.SECONDARY}
                className="bg-borderColor"
                onClick={() => history.push(Routes.LOGIN)}
                testId="login:return:link"
              >
                Return to login
              </Button>
            </Splash>
          </div>
        </div>
      ) : (
        <OnboardingLayout>
          <OnboardingLayout.LeftContent>
            <div className="hidden md:block">
              <Icon.Hum />
            </div>
            <OnboardingLayout.Header title="Reset password" />
            <OnboardingLayout.Footer>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/login" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.Footer>
          </OnboardingLayout.LeftContent>
          <OnboardingLayout.RightContent>
            <div className="max-w-[400px] w-full">
              <form onSubmit={form.handleSubmit} role="form">
                <TextInputField
                  label="Email"
                  aria-label="Email"
                  inputTestId="login:email:recover"
                  {...fields.email.input}
                />

                <Button
                  fullWidth
                  loading={form.isSubmitting}
                  testId="reset_password:button:send_link"
                  submit
                >
                  Recover my account
                </Button>
              </form>
              <OnboardingLayout.FooterMobile>
                <Text type={TextType.BODY_MICO} subdued>
                  Already a member?{' '}
                  <a href="/login" className="ui-link text-bodyMicoSize">
                    Login
                  </a>
                </Text>
              </OnboardingLayout.FooterMobile>
            </div>
          </OnboardingLayout.RightContent>
        </OnboardingLayout>
      )}
    </>
  );
};
