import React from 'react';

import { PageTitle } from '@hum/legacy-ui';
import { CompanyFileDocumentType } from '@hum/types';

import { UploadCSV } from './UploadCSV';
import { InvitedCompanies } from './Companies';

export const InviteCompanies = ({
  companyId,
}: {
  companyId: number;
  companyName?: string;
}) => {
  return (
    <>
      <PageTitle
        title="Invite companies to the Intelligent Capital Market"
        noCenter
        action={
          <UploadCSV
            companyId={companyId}
            documentType={
              CompanyFileDocumentType.PorfolioMonitoringImportSource
            }
            accept=".csv"
          />
        }
      />
      <InvitedCompanies companyId={companyId} />
    </>
  );
};
