import React from 'react';
import styled from 'styled-components';

const StyledOutsideUS = styled.div`
  border-radius: 4px;
  background: #1f2630;
  display: flex;
  align-items: start;
  padding: 16px;
  user-select: none;
`;
const Link = styled.a`
  color: #92abfa;
  cursor: pointer;
`;

const Svg = styled.svg`
  width: 20px;
  height: 20px;
  margin-right: 16px;
  flex-basis: 33px;
`;
const TextDisclaimer = styled.p`
  font-size: 13px;
`;
const linkLearnMore =
  'https://info.humcapital.com/hum-help-center/are-there-any-industry-or-country-restrictions-to-working-with-hum-capital';
const InfoIcon = () => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1010_4187)">
        <path
          d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.3333V10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66666H10.0083"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1010_4187">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export const OutsideUS = () => {
  return (
    <StyledOutsideUS>
      <InfoIcon />
      <TextDisclaimer>
        At this time, Hum only supports transactions to raise capital through a
        US organized entity.{' '}
        <Link
          href={linkLearnMore}
          rel="noreferrer"
          target="_blank"
          className="ui-link"
        >
          Learn more.
        </Link>
      </TextDisclaimer>
    </StyledOutsideUS>
  );
};
