import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useResendVerificationEmail } from './useVerifyEmail';
import Helmet from 'react-helmet';
import { Button, ButtonType, Icon, Splash, Topbar } from '@hum/ui-library';
import { Transition } from '@headlessui/react';
import { useAnalyticsEvent } from '@hum/api';

const useSignUpComplete = () => {
  const trackEvent = useAnalyticsEvent();

  useEffect(() => {
    trackEvent('company-visit-verify-email');
  }, []);
};

export const SignUpComplete = () => {
  const {
    state: { signUpEmail } = { signUpEmail: 'your inbox' },
  } = useLocation<{ signUpEmail?: string }>();
  useSignUpComplete();

  const { resendVerificationEmail } = useResendVerificationEmail();
  const [animation, setAnimation] = React.useState(false);

  function footerClick(e) {
    e.preventDefault();
    resendVerificationEmail();
  }
  useEffect(() => {
    setAnimation(true);
  }, []);

  return (
    <>
      <Helmet title="Verify your email" />
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <div className="flex h-screen">
        <div className="flex flex-col items-center justify-around w-full">
          <Splash
            testId="signup-complete"
            graphic={
              <Transition
                show={animation}
                enter="transform transition duration-700"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
              >
                <Icon.VerifyEmail className="max-w-[200px] mx-auto" />
              </Transition>
            }
            title="Verify your email to continue"
            subtitle={`We’ve sent a verification email to ${signUpEmail}. Click the link inside to continue.`}
          >
            <Button
              type={ButtonType.SECONDARY}
              className="bg-borderColor"
              onClick={footerClick}
            >
              Resend email
            </Button>
          </Splash>
        </div>
      </div>
    </>
  );
};
