import React from 'react';
import { InputMode, SelectInputField, TextInputField } from '@hum/legacy-ui';
import {
  OnboardingLayout,
  Button,
  Icon,
  TextType,
  Text,
  Topbar,
} from '@hum/ui-library';
import {
  LAST_FUNDING_ROUND_OPTIONS,
  EQUITY_RAISED_SELECT_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { useFundraisingHistory } from './useFundraisingHistory';

export function FundraisingHistory() {
  const { form, fields, disableAdditionalFields } = useFundraisingHistory();
  const title = 'Tell us about your fundraising history';
  const subtitle =
    'We will use this information to match you with Investors looking for companies at your funding stage.';

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/logout" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full">
            <form onSubmit={form.handleSubmit} role="form">
              {/* New field */}
              <SelectInputField
                {...fields.lastStageOfFunding}
                label="Most recent financing round"
                inputAriaLabel="Most recent financing round"
                data-testid="signup:last-stage-of-funding"
                options={LAST_FUNDING_ROUND_OPTIONS}
              />
              {/* New field */}
              <TextInputField
                {...fields.dateOfLastRaise}
                inputMode={InputMode.MONTH_YEAR_DATE}
                label="Date of last raise (optional)"
                infoText="MM/YYYY"
                inputAriaLabel="Date of last raise"
                inputTestId="signup:date-of-last-raise"
                disabled={disableAdditionalFields}
              />
              <SelectInputField
                {...fields.totalEquityRaised}
                label="Total equity raised to date (optional)"
                isClearable
                inputAriaLabel="Total equity raised to date (optional)"
                data-testid="signup:total-equity-raised"
                options={EQUITY_RAISED_SELECT_OPTIONS}
                disabled={disableAdditionalFields}
              />
              <TextInputField
                {...fields.lastValuation}
                label="Last valuation (optional)"
                inputMode={InputMode.DOLLAR}
                inputTestId="signup:last-valuation"
                inputAriaLabel="Last valuation (optional)"
                disabled={disableAdditionalFields}
              />
              <Button
                fullWidth
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
                testId="link:fundraising-goals"
                submit
              >
                Next
              </Button>
            </form>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/logout" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
}
