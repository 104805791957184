import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { API } from '@hum/icm-app/src/backend';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { login, useApiMutation } from '@hum/api';
import { getAuthRedirectUrl } from '@hum/icm-app/src/sagas/auth';
import { useHistory } from 'react-router-dom';

const initialValues = { email: '', password: '' };
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const useLogin: any = () => {
  const flags = useFlags();
  const history = useHistory();
  const dispatch = useDispatch();
  const api: API = useAPI();
  const { mutate: userLogin } = useApiMutation(login, {
    onSuccess: ({ user }) => {
      const redirectUrl = getAuthRedirectUrl(user, window.location.href);
      if (redirectUrl) {
        const url = new URL(redirectUrl);
        if (url.hostname === window.location.hostname) {
          window.location.assign(redirectUrl);
        } else {
          history.push(url.pathname + url.search || '');
        }
      }
    },
  });

  const form = useForm({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (payload) => {
      flags.enabled('use-react-query')
        ? userLogin(payload)
        : await api.auth.login(dispatch)(payload);
    },
  });

  const email = useField('email', 'text', form).input;
  const password = useField('password', 'text', form).input;

  return {
    form,
    oauth: {},
    fields: {
      email,
      password,
    },
  };
};
