import { useApiQuery, resendVerifyEmail } from '@hum/api';
import { resendVerificationEmailButtonClicked } from '@hum/icm-app/src/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useDispatch } from 'react-redux';

export function useResendVerificationEmail() {
  const flags = useFlags();
  const { refetch } = useApiQuery(resendVerifyEmail, {
    enabled: false,
  });
  const dispatch = useDispatch();

  const resendVerificationEmail = () => {
    flags.enabled('use-react-query')
      ? refetch()
      : dispatch(resendVerificationEmailButtonClicked());
  };

  return {
    resendVerificationEmail,
  };
}
