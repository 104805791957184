import pickBy from 'lodash/pickBy';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useField, useForm } from '@hum/common/src/modules/form';
import { signUpV5RequestSent } from '@hum/icm-app/src/actions';
import { useUrlQuery } from '@hum/common/src/hooks/useUrlQuery';
import {
  getSubdomain,
  GoogleAdsConversions,
  LinkedInAdsConversions,
  trackGoogleAdsConversion,
  trackLinkedInAdsConversion,
} from '@hum/icm-app/src/utils';
import { URL_REGEX } from '@hum/icm-app/src/utils/url';

import * as Yup from 'yup';
import { createSignupV5, useAnalyticsEvent, useApiMutation } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { Routes } from '@hum/icm-app/src/state';
import { useHistory } from 'react-router-dom';

export const WORK_EMAIL = 'workEmail';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const COMPANY_ROLE = 'companyRole';
export const COMPANY_NAME = 'companyName';
export const WEBSITE = 'website';

export const validationSchema = Yup.object().shape({
  [WORK_EMAIL]: Yup.string()
    .required('Required')
    .email('Must be a valid email'),
  [FIRST_NAME]: Yup.string().required('Required'),
  [LAST_NAME]: Yup.string().required('Required'),
  [COMPANY_ROLE]: Yup.string().required('Required'),
  [COMPANY_NAME]: Yup.string().required('Required'),
  [WEBSITE]: Yup.string()
    .trim()
    .matches(URL_REGEX, 'Please enter a valid URL')
    .required('Website is required'),
  password: Yup.string()
    .required('Required')
    .min(10, 'Password is too short, at least 10 characters required'),
});

const { subdomain } = getSubdomain();
const initialValues = {
  [WORK_EMAIL]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [COMPANY_ROLE]: '',
  [COMPANY_NAME]: '',
  [WEBSITE]: '',
  password: '',
  fundRaisingTimeFrame: '',
  acceptedPrivacyPolicy: true,
  acceptedTos: true,
  acceptedCommunications: true,
  invitationHash: undefined,
  utMedium: undefined,
  utmSource: undefined,
  utmCampaign: undefined,
  utmTerm: undefined,
  utmContent: undefined,
  channel: undefined,
  subdomain: subdomain,
};

export const useChooseYourAdventureSignUp = () => {
  const flags = useFlags();
  const history = useHistory();
  const dispatch = useDispatch();
  const trackEvent = useAnalyticsEvent();
  const [
    {
      invitation_hash: companyInvitationHash,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
      channel: channel,
      fundRaisingTimeFrame: fundRaisingTimeFrame,
      email: email,
    },
  ] = useUrlQuery();
  const { mutate: postSignup } = useApiMutation(createSignupV5, {
    onSuccess: () => {
      history.push(Routes.ABOUT_YOUR_BUSINESS, {
        signupEmail: email,
      });
    },
  });

  useEffect(() => {
    trackEvent('company-visit-sign-up');
  }, []);

  const form = useForm({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      const { website, ...rest } = payload;
      const fullWebsite =
        website.startsWith('http://') || website.startsWith('https://')
          ? website.toLowerCase()
          : `https://${website.toLowerCase()}`;

      // FIXME: this isn't describing what happened. See https://github.com/captec/capital/blob/master/frontend/docs/architecture.md#component-dos-and-donts
      if (flags.enabled('use-react-query')) {
        postSignup({
          website: fullWebsite,
          ...rest,
          application: pickBy({
            fundRaisingTimeFrame,
          }),
        });
      } else {
        dispatch(
          signUpV5RequestSent({
            website: fullWebsite,
            ...rest,
            application: pickBy({
              fundRaisingTimeFrame,
            }),
          })
        );
      }

      trackGoogleAdsConversion(GoogleAdsConversions.companySignupSubmit);
      trackLinkedInAdsConversion(LinkedInAdsConversions.companySignupSubmit);
    },
  });

  const workEmail = useField(WORK_EMAIL, 'text', form).input;
  const firstName = useField(FIRST_NAME, 'text', form).input;
  const lastName = useField(LAST_NAME, 'text', form).input;
  const companyRole = useField(COMPANY_ROLE, 'text', form).input;
  const legalBusinessName = useField(COMPANY_NAME, 'text', form).input;
  const website = useField(WEBSITE, 'text', form).input;
  const password = useField('password', 'text', form).input;
  const acceptedCommunications = useField(
    'acceptedCommunications',
    'checkbox',
    form
  ).input;

  useEffect(() => {
    form.setFieldValue('invitationHash', companyInvitationHash);
    form.setFieldValue('subdomain', subdomain);
    form.setFieldValue('utmMedium', utmMedium);
    form.setFieldValue('utmSource', utmSource);
    form.setFieldValue('utmCampaign', utmCampaign);
    form.setFieldValue('utmTerm', utmTerm);
    form.setFieldValue('utmContent', utmContent);
    form.setFieldValue('channel', channel);
    form.setFieldValue('fundRaisingTimeFrame', fundRaisingTimeFrame);
    form.setFieldValue('workEmail', email);
  }, [
    subdomain,
    companyInvitationHash,
    utmMedium,
    utmSource,
    utmCampaign,
    utmTerm,
    utmContent,
    channel,
    fundRaisingTimeFrame,
    email,
  ]);

  useEffect(() => {
    if (acceptedCommunications.value) {
      trackEvent('signup:accept-communications');
    }
  }, [acceptedCommunications.value]);

  return {
    form,
    initialValues,
    fields: {
      workEmail,
      firstName,
      lastName,
      companyRole,
      legalBusinessName,
      website,
      password,
      acceptedCommunications,
    },
  };
};
