import { actionCreator, BaseAction } from '@hum/common/src/ducks/actions/base';
import { Result } from '@hum/common/src/ducks/state/result';
import { CreditBoxReport, ReportType, ChannelType } from '@hum/types';

export enum CreditBoxInvestorsActionType {
  CREDIT_BOX_PULL_ALL_REPORTS_BUTTON_CLICKED = 'CREDIT_BOX_PULL_ALL_REPORTS_BUTTON_CLICKED',
  CREDIT_BOX_REFRESH_BUTTON_CLICKED = 'CREDIT_BOX_REFRESH_BUTTON_CLICKED',
  CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED = 'CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED',
  IS_DOWNLOADING_CREDIT_BOX_REPORT = 'IS_CREDIT_BOX_GENERATING_REPORT',
  CREDIT_BOX_CREATED_SUCCESSFULLY = 'CREDIT_BOX_CREATED_SUCCESSFULLY',
  CREDIT_BOX_CREATED_UNSUCCESSFULLY = 'CREDIT_BOX_CREATED_UNSUCCESSFULLY',
  REPORT_DOWNLOADED_UNSUCCESSFULLY = 'REPORT_DOWNLOADED_UNSUCCESSFULLY',
  CREDIT_BOX_REPORTS_HOOK_USED = 'CREDIT_BOX_REPORTS_HOOK_USED',
  CREDIT_BOX_REPORTS_STATE_CHANGED = 'CREDIT_BOX_REPORTS_STATE_CHANGED',
  CREDIT_BOX_REPORT_REFRESHED_STATE_CHANGED = 'CREDIT_BOX_REPORT_REFRESHED_STATE_CHANGED',
}

export type CreditBoxReportsHookUsed = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_HOOK_USED,
  { companyId: number }
>;

export type CreditBoxPullAllReportsButtonClicked = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_PULL_ALL_REPORTS_BUTTON_CLICKED,
  { companyId: number }
>;

export type CreditBoxRefreshButtonClicked = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_REFRESH_BUTTON_CLICKED,
  { companyId: number; reportType: ReportType }
>;
export type CreditBoxDownloadButtonClicked = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED,
  { companyId: number; reportId: string } & {
    companyName?: string;
    userEmail?: string;
    companyOnboardingChannel?: ChannelType | string | null;
    companyUtmSource?: string | null;
  }
>;
export type CreditBoxCreatedSuccessfully = BaseAction<CreditBoxInvestorsActionType.CREDIT_BOX_CREATED_SUCCESSFULLY>;
export type CreditBoxCreatedUnsuccessfully = BaseAction<CreditBoxInvestorsActionType.CREDIT_BOX_CREATED_UNSUCCESSFULLY>;
export type ReportDownloadedUnsuccessfully = BaseAction<CreditBoxInvestorsActionType.REPORT_DOWNLOADED_UNSUCCESSFULLY>;
export type CreditBoxReportsStateChanged = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_STATE_CHANGED,
  Result<CreditBoxReport[]>
>;
export type CreditBoxReportRefreshedStateChanged = BaseAction<
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORT_REFRESHED_STATE_CHANGED,
  Result<CreditBoxReport[]>
>;
export type IsDownloadingCreditBoxReport = BaseAction<CreditBoxInvestorsActionType.IS_DOWNLOADING_CREDIT_BOX_REPORT>;

export const creditBoxReportsHookUsed = actionCreator<CreditBoxReportsHookUsed>(
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_HOOK_USED
);
export const creditBoxReportsStateChanged = actionCreator<CreditBoxReportsStateChanged>(
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_STATE_CHANGED
);
export const creditBoxReportRefreshedStateChanged = actionCreator<CreditBoxReportRefreshedStateChanged>(
  CreditBoxInvestorsActionType.CREDIT_BOX_REPORT_REFRESHED_STATE_CHANGED
);
export const creditBoxPullAllReportsButtonClicked = actionCreator<CreditBoxPullAllReportsButtonClicked>(
  CreditBoxInvestorsActionType.CREDIT_BOX_PULL_ALL_REPORTS_BUTTON_CLICKED
);
export const creditBoxRefreshButtonClicked = actionCreator<CreditBoxRefreshButtonClicked>(
  CreditBoxInvestorsActionType.CREDIT_BOX_REFRESH_BUTTON_CLICKED
);
export const creditBoxDownloadButtonClicked = actionCreator<CreditBoxDownloadButtonClicked>(
  CreditBoxInvestorsActionType.CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED
);
export const creditBoxCreatedSuccessfully = actionCreator<CreditBoxCreatedSuccessfully>(
  CreditBoxInvestorsActionType.CREDIT_BOX_CREATED_SUCCESSFULLY
);
export const creditBoxCreatedUnsuccessfully = actionCreator<CreditBoxCreatedUnsuccessfully>(
  CreditBoxInvestorsActionType.CREDIT_BOX_CREATED_UNSUCCESSFULLY
);
export const reportDownloadedUnsuccessfully = actionCreator<ReportDownloadedUnsuccessfully>(
  CreditBoxInvestorsActionType.REPORT_DOWNLOADED_UNSUCCESSFULLY
);
export const isDownloadingCreditBoxReport = actionCreator<IsDownloadingCreditBoxReport>(
  CreditBoxInvestorsActionType.IS_DOWNLOADING_CREDIT_BOX_REPORT
);

export type CreditBoxReportsActions =
  | CreditBoxReportsHookUsed
  | CreditBoxPullAllReportsButtonClicked
  | CreditBoxRefreshButtonClicked
  | CreditBoxDownloadButtonClicked
  | CreditBoxCreatedSuccessfully
  | CreditBoxCreatedUnsuccessfully
  | ReportDownloadedUnsuccessfully
  | CreditBoxReportsStateChanged
  | CreditBoxReportRefreshedStateChanged
  | IsDownloadingCreditBoxReport;
