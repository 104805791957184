import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Help } from '@hum/legacy-ui';
import infoIcon from '@hum/assets/icons/info-circle-icon.png';
import { Icon, Text, TextType } from '@hum/ui-library';
import { Transition } from '@headlessui/react';

export const UnsupportedCountry = () => {
  const [animation, setAnimation] = React.useState(false);

  useEffect(() => {
    setAnimation(true);
  }, []);

  return (
    <div className="h-screen">
      <Helmet title="Unsupported country" />
      <div className="flex flex-col items-center justify-center h-full">
        <div className="max-w-[500px] px-4 user-select-none">
          <Transition
            show={animation}
            enter="transform transition duration-700"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
          >
            <Icon.UnsupportedCountry className="mx-auto" />
          </Transition>
          <Text type={TextType.DISPLAY_S} className="text-center my-4">
            Thank you for your interest in Hum.
          </Text>
          <Text
            type={TextType.BODY_M}
            className="text-center mt-6 mb-10"
            subdued
          >
            Unfortunately, at this time Hum does not offer financing support in
            your country. If you’d like to provide additional information,
            please contact us.
          </Text>
        </div>
        <Help className="nofilter" icon={<img src={infoIcon} />}>
          <Text type={TextType.BODY_S} subdued>
            Questions? Contact Hum team
          </Text>
        </Help>
      </div>
    </div>
  );
};
