import { client as backend } from '@hum/common/src/api/client';
import React from 'react';
import { formatDate, openOAUthWindow2 } from '@hum/icm-app/src/utils';
import { docusignServiceConnected } from '@hum/icm-app/src/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useDocusignConnector } from '@hum/icm-app/src/hooks/useDocusignConnector';
import { DocuSignConnectorStatus } from '@hum/icm-app/src/state';

import { Pill } from '@hum/legacy-ui';
import { Button, Spinner } from '@hum/ui-library';

const getDocuSignConnectURL = async () => {
  try {
    const result = await backend.get(`/docusign/authorization`);
    return result.connect_url;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};

const DocuSignOAuthConnectorButton: React.FC<{
  disabled?: boolean;
  loading?: boolean;
}> = ({ disabled, loading }) => {
  const { dispatch } = useAppStore();

  const onConnectClick = async () => {
    const url = await getDocuSignConnectURL();
    if (url) {
      const params = await openOAUthWindow2(url);
      dispatch(docusignServiceConnected({ params }));
    }
  };

  return (
    <Button
      testId="link-button"
      onClick={onConnectClick}
      disabled={disabled}
      loading={loading}
    >
      Connect
    </Button>
  );
};

export const DocuSignSection = () => {
  const { data: docuSignConnector, loaded } = useDocusignConnector();

  if (!loaded) {
    return <Spinner fullScreen />;
  }

  const isConnected =
    docuSignConnector &&
    docuSignConnector.status === DocuSignConnectorStatus.Connected;
  const isExpired =
    docuSignConnector &&
    docuSignConnector.status === DocuSignConnectorStatus.Expired;
  const isDisconnected = !docuSignConnector;

  return (
    <>
      <h2>DocuSign</h2>
      <div>
        <div>
          Status:
          <Pill
            positive={isConnected}
            negative={isExpired}
            notice={isDisconnected}
          >
            {isExpired ? (
              <span title="We lost access to this connection">expired</span>
            ) : isConnected ? (
              'connected'
            ) : isDisconnected ? (
              'not connected'
            ) : null}
          </Pill>
        </div>
        {docuSignConnector ? (
          <>
            <div>Last update: {formatDate(docuSignConnector.updatedAt)}</div>
            <div>Expires at: {formatDate(docuSignConnector.expiresAt)}</div>
          </>
        ) : null}
      </div>
      <div className="pt-2">
        <DocuSignOAuthConnectorButton />
      </div>
    </>
  );
};
