// Libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Modal } from '@hum/design-system';
import { Detail, FileIcon, Pill } from '@hum/legacy-ui';
import {
  Button,
  ButtonSize,
  ButtonType,
  DescriptionList,
  InfoSection,
  Text,
  TextType,
} from '@hum/ui-library';
import {
  EMPTY_ARRAY,
  SyndicationMatchEvent,
  AdminMatchSyndicationPermissions,
} from '@hum/icm-app/src/state';
import {
  SyndicationMatch,
  SyndicationMatchActionStatus,
  SyndicationMatchStatus,
  CompanyFile,
} from '@hum/types';
import { formatDate } from '@hum/icm-app/src/utils';
import { useSyndicationMatchEvents } from '@hum/icm-app/src/hooks/syndication';

// Other
import { FileItem } from '@hum/icm-app/src/pages/admin/DocumentManagement/styles.pc';
import { useSyndicationOutOfFeeProofFiles } from '@hum/icm-app/src/hooks/useSyndicationOutOfFeeProofFiles';
import { useDownloadFile } from '@hum/icm-app/src/__DEPRECATED__modules/files/hooks/useFileManager/download';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import * as styles from './styles.pc';
import { UpdateMatchStatus } from './UpdateMatchStatus';
import { adminClickedSendIntros } from '@hum/icm-app/src/actions';
import { toast } from '@hum/common/src/modules/toast';
import { useApiMutation, sendTeaserEmail } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

const successfulMatchStatuses = (): string[] => {
  return [
    SyndicationMatchStatus.Matched,
    SyndicationMatchStatus.TermSheetSigned,
    SyndicationMatchStatus.Closed,
  ];
};

const erroneousMatchStatuses = (): string[] => {
  return [SyndicationMatchStatus.NoMatched, SyndicationMatchStatus.Failed];
};

export const Match = ({
  match,
  companyIsCompany,
  disableSmartMatch,
}: {
  match: SyndicationMatch;
  companyIsCompany: boolean;
  permsData?: AdminMatchSyndicationPermissions;
  disableSmartMatch?: boolean;
}) => {
  const { dispatch } = useAppStore();
  const flags = useFlags();

  const [showDetails, setShowDetails] = useState(false);
  const [changeMatchStatusModalOpen, setChangeMatchStatusModalOpen] = useState(
    false
  );

  const { data, loadEvents } = useSyndicationMatchEvents(
    match.companyId,
    match.id
  );
  const { download: downloadOutOfFeeFile } = useDownloadFile(
    match.syndicationInvestor!.companyId!
  );

  const {
    syndicationMatchOutOfFeeFiles: { data: outOfFeeFiles = EMPTY_ARRAY },
    loadOutOfFeeFiles,
  } = useSyndicationOutOfFeeProofFiles(match.companyId, match.id);

  useEffect(() => {
    if (showDetails) {
      loadEvents();
      loadOutOfFeeFiles();
    }
  }, [showDetails]);

  const companyViews = data?.views.company || 0;
  const investorViews = data?.views.syndicationInvestor || 0;
  const matchEvents: SyndicationMatchEvent['events'] = data?.events || [];

  const counterpartName = companyIsCompany
    ? match.syndicationInvestor.company?.name
    : match.company?.name;
  const counterpartId = companyIsCompany
    ? match.syndicationInvestor.company?.id
    : match.company?.id;
  const syndicationInvestorCompanyId = match.syndicationInvestor.company?.id;

  const { mutate: resendTeaser, isLoading } = useApiMutation(sendTeaserEmail);

  const onSendIntrosClick = async () => {
    try {
      dispatch(
        adminClickedSendIntros({
          companyId: match.companyId,
          adminMatchPermissions: true,
          matchIds: [match.id],
        })
      );
    } catch (error: any) {
      toast.error(error?.message || 'Failed to send teaser');
    }
  };

  return (
    <styles.StyledSyndicationMatch>
      <styles.SyndicationMatchHead>
        <div className="flex-1">
          <h2 className="text-body-medium">
            <Link
              to={{
                pathname: '/admin',
                search: `?company_id=${counterpartId}`,
              }}
              className="ui-link"
            >
              {counterpartName}
            </Link>

            <Pill
              positive={successfulMatchStatuses().includes(match.status)}
              negative={erroneousMatchStatuses().includes(match.status)}
              notice={[SyndicationMatchStatus.Sent].includes(match.status)}
              className="ml-4"
            >
              {match.status}
            </Pill>
          </h2>
          <div className="flex mb-4 mt-2">
            <Text type={TextType.BODY_S} subdued>
              Investor
            </Text>
            <Pill
              className="ml-4"
              positive={
                match.syndicationInvestorActionStatus ===
                SyndicationMatchActionStatus.Accepted
              }
              negative={
                match.syndicationInvestorActionStatus ===
                SyndicationMatchActionStatus.Rejected
              }
            >
              {match.syndicationInvestorActionStatus}
            </Pill>
            {match.optOutOfFee && <Pill notice>OPTED OUT OF FEE</Pill>}
          </div>
          <div>
            <Button
              onClick={() => setChangeMatchStatusModalOpen(true)}
              disabled={disableSmartMatch}
              testId="match:change-status"
              className="mr-2"
            >
              Change status
            </Button>
            {changeMatchStatusModalOpen && (
              <Modal
                header="Update match status"
                onClose={() => setChangeMatchStatusModalOpen(false)}
              >
                <UpdateMatchStatus
                  companyId={match.companyId}
                  syndicationInvestorCompanyId={syndicationInvestorCompanyId!}
                  status={match.status}
                  matchId={match.id}
                  companyIsCompany={companyIsCompany}
                  closeModal={() => setChangeMatchStatusModalOpen(false)}
                />
              </Modal>
            )}
            {(match.status === SyndicationMatchStatus.IntroNeedsApproval ||
              match.status === SyndicationMatchStatus.Sent) && (
              <Button
                disabled={disableSmartMatch}
                onClick={onSendIntrosClick}
                testId="match:send-intro"
                className="mr-2"
              >
                {match.status === SyndicationMatchStatus.IntroNeedsApproval
                  ? 'Send'
                  : 'Resend'}{' '}
                teaser
              </Button>
            )}
            {flags.enabled('enable-resend-teasers') &&
              match.status === SyndicationMatchStatus.Matched && (
                <Button
                  className="mr-2"
                  disabled={isLoading}
                  onClick={() => resendTeaser({ matchUuid: match.uuid })}
                  testId="match:resend-teaser"
                >
                  Resend match intro
                </Button>
              )}
          </div>
        </div>
        <div>
          <Button
            type={ButtonType.SECONDARY}
            className="px-3 self-center"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      </styles.SyndicationMatchHead>
      {showDetails && (
        <div className="flex mt-8">
          <div className="w-1/2">
            <InfoSection title="Company Actions">
              <DescriptionList>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Viewed
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.companyViewedAt &&
                      formatDate(match.companyViewedAt)) ||
                      '–'}
                  </Text>
                </dd>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Last Update
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.companyActionUpdatedAt &&
                      formatDate(match.companyActionUpdatedAt)) ||
                      '–'}
                  </Text>
                </dd>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Sent Intro
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.companySentAt && formatDate(match.companySentAt)) ||
                      '–'}
                  </Text>
                </dd>
              </DescriptionList>
            </InfoSection>
            <InfoSection title="Investor Actions">
              <DescriptionList>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Viewed
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.syndicationInvestorViewedAt &&
                      formatDate(match.syndicationInvestorViewedAt)) ||
                      '–'}
                  </Text>
                </dd>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Last Update
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.syndicationInvestorActionUpdatedAt &&
                      formatDate(match.syndicationInvestorActionUpdatedAt)) ||
                      '–'}
                  </Text>
                </dd>
                <dt>
                  <Text type={TextType.BODY_S} subdued>
                    Sent Intro
                  </Text>
                </dt>
                <dd>
                  <Text type={TextType.BODY_S}>
                    {(match.syndicationInvestorSentAt &&
                      formatDate(match.syndicationInvestorSentAt)) ||
                      '–'}
                  </Text>
                </dd>
              </DescriptionList>
            </InfoSection>
            {match.optOutOfFee && outOfFeeFiles?.length > 0 && (
              <InfoSection title="Opt out of fee files">
                {outOfFeeFiles.map((f: CompanyFile) => (
                  <FileItem
                    icon={<FileIcon size={20} mimetype={f.type} />}
                    controls={
                      <Button
                        size={ButtonSize.SLIM}
                        onClick={() => downloadOutOfFeeFile(f.id)}
                      >
                        download
                      </Button>
                    }
                    key={f.id}
                    title={`${f.name}.${f.ext}`}
                    timestamp={formatDate(f.createdAt)}
                    fileName={`${f.name}.${f.ext}`}
                    lower={''}
                  />
                ))}
              </InfoSection>
            )}
          </div>
          <div className="w-1/2 ml-5">
            <div className="flex mb-5 justify-between">
              <Detail
                title="Investor Views"
                description={String(investorViews || 0)}
              />
              <Detail
                title="Company Views"
                description={String(companyViews || 0)}
              />
            </div>
            <InfoSection title="Match Activity">
              {matchEvents.map((event) => (
                <Detail
                  key={event.date}
                  title={event.name}
                  description={formatDate(event.date)}
                  marginBottomLarge
                />
              ))}
            </InfoSection>
          </div>
        </div>
      )}
    </styles.StyledSyndicationMatch>
  );
};
