import React, { useRef } from 'react';
import { Button, ButtonType } from '@hum/ui-library';
import { CompanyFileDocumentType } from '@hum/types';
import { PM_sourceCsvFileUploaded } from '@hum/icm-app/src/actions';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { toast } from '@hum/common/src/modules/toast';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { bulkInvestorInvites, useApiMutation } from '@hum/api';

export const UploadCSV = ({
  companyId,
  documentType,
  accept,
}: {
  companyId: number;
  documentType: CompanyFileDocumentType;
  accept: string;
}) => {
  const flags = useFlags();
  const { mutate: uploadCsvFile } = useApiMutation(bulkInvestorInvites);
  const { dispatch } = useAppStore();
  const { createFromLocalFile } = useFileManager({ companyId });

  const inputRef = useRef<HTMLInputElement>(null);
  const openFileInput = () => inputRef.current!.click();

  const onLocalFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = Array.from(e.target.files || [])[0];

    // cleanup selection
    e.target.value = '';

    const created = await createFromLocalFile({
      reference: documentType,
      input: { ...selectedFile, documentType, meta: { uploadedByAdmin: true } },
      file: selectedFile,
    });
    if (flags.enabled('use-react-query')) {
      uploadCsvFile({ companyId, fileId: created.id });
    } else {
      dispatch(PM_sourceCsvFileUploaded({ companyId, fileId: created.id }));
      toast.success('File uploaded');
    }
  };

  return (
    <>
      <Button type={ButtonType.SECONDARY} onClick={openFileInput}>
        Import via CSV
      </Button>

      <input
        ref={inputRef}
        type="file"
        data-test-id="file-input"
        onChange={onLocalFileSelect}
        className="hidden"
        accept={accept}
      />
    </>
  );
};
