import { noop } from 'lodash';
import { ReportType, CreditBoxReport } from '@hum/types';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { all, call, takeLatest, put, delay } from 'redux-saga/effects';
import {
  creditBoxCreatedSuccessfully,
  creditBoxCreatedUnsuccessfully,
  reportDownloadedUnsuccessfully,
  CreditBoxInvestorsActionType,
  creditBoxReportsStateChanged,
  creditBoxReportRefreshedStateChanged,
  isDownloadingCreditBoxReport,
} from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/ducks/actions';
import { trackActions } from '@hum/icm-app/src/modules/analytics';
import { getAPI } from '@hum/icm-app/src/sagas/utils';
import { API } from '@hum/icm-app/src/backend';
import { toast } from '@hum/common/src/modules/toast';

export function* refreshCreditBoxReport(
  companyId: number,
  reportType: ReportType
) {
  const api: API = yield getAPI();

  try {
    const reportApi = yield call(
      api.admin.createCreditBoxReport,
      companyId,
      reportType
    );

    yield put(
      creditBoxReportRefreshedStateChanged({
        data: [reportApi],
        loaded: true,
      })
    );
    while (true) {
      const updatedReport = yield call(
        api.admin.getCreditBoxReport,
        companyId,
        reportApi.id
      );
      yield put(
        creditBoxReportRefreshedStateChanged({
          data: [updatedReport],
          loaded: true,
        })
      );
      if (updatedReport.status === 'success') {
        yield call(() => toast.success('Credit box successfully refreshed'));
        yield put(creditBoxCreatedSuccessfully());
        break;
      } else if (updatedReport.status === 'failed') {
        yield call(() =>
          toast.error('We couldn’t refresh the credit box.', {
            description: 'Please try again later.',
          })
        );
        yield put(creditBoxCreatedUnsuccessfully());
        break;
      }
      yield delay(3000);
    }
  } catch (error) {
    yield call(() =>
      toast.error('We couldn’t refresh the credit box.', {
        description: 'Please try again later.',
      })
    );
    yield put(creditBoxCreatedUnsuccessfully());
    throw error;
  }
}

export function* pullAllCreditBoxesReports(companyId: number) {
  const api: API = yield getAPI();
  const reportTypes = Object.values(ReportType);
  try {
    const reportsApi: CreditBoxReport[] = yield all(
      reportTypes.map((type) =>
        api.admin.createCreditBoxReport(companyId, type)
      )
    );

    yield put(
      creditBoxReportsStateChanged({
        data: reportsApi,
        loaded: true,
      })
    );

    while (true) {
      const updatedReports = yield all(
        reportsApi.map((report) =>
          api.admin.getCreditBoxReport(companyId, report.id)
        )
      );

      yield put(
        creditBoxReportsStateChanged({
          data: updatedReports,
          loaded: true,
        })
      );
      if (updatedReports[0].status === 'success') {
        yield call(() => toast.success('Credit boxes successfully refreshed'));
        yield put(creditBoxCreatedSuccessfully());
        break;
      } else if (updatedReports[0].status === 'failed') {
        yield call(() =>
          toast.error('We couldn’t refresh the credit boxes.', {
            description: 'Please try again later.',
          })
        );
        yield put(creditBoxCreatedUnsuccessfully());
        break;
      }
      yield delay(3000);
    }
  } catch (error) {
    yield call(() =>
      toast.error('We couldn’t refresh the credit boxes.', {
        description: 'Please try again later.',
      })
    );
    yield put(creditBoxCreatedUnsuccessfully());
    throw error;
  }
}

export function* getMultipleCreditBoxReports(companyId: number) {
  const api: API = yield getAPI();
  const reportTypes = Object.values(ReportType);
  try {
    const reportsApi = yield all(
      reportTypes.map((type) => api.admin.getCreditBoxReports(companyId, type))
    );

    const formattedReports = reportsApi.reduce(
      (reports, report) => [...reports, ...report],
      []
    );

    if (formattedReports.length > 0) {
      yield put(
        creditBoxReportsStateChanged({
          data: formattedReports,
          loaded: true,
        })
      );
    } else {
      yield put(
        creditBoxReportsStateChanged({
          data: undefined,
          loaded: true,
        })
      );
    }
  } catch (error) {
    yield put(
      creditBoxReportsStateChanged({
        loaded: false,
      })
    );
    throw error;
  }
}

export function* createCreditBoxReportDownloadLink(
  companyId: number,
  reportId: string
) {
  const api: API = yield getAPI();
  try {
    while (true) {
      const report = yield call(
        api.admin.getCreditBoxReport,
        companyId,
        reportId
      );
      if (report.status === 'success') {
        const { url } = yield call(
          api.admin.downloadCreditBoxReport,
          companyId,
          reportId
        );
        window.location.assign(url);
        break;
      } else if (report.status === 'failed') {
        yield call(() =>
          toast.error('We couldn’t download the report.', {
            description: 'Please try again later.',
          })
        );
        yield put(reportDownloadedUnsuccessfully());
        break;
      } else {
        yield put(isDownloadingCreditBoxReport());
      }
      yield delay(3000);
    }
  } catch (error) {
    yield call(() =>
      toast.error('We couldn’t download the report.', {
        description: 'Please try again later.',
      })
    );
    yield put(reportDownloadedUnsuccessfully());
    throw error;
  }
}

export function* handlePullAllCreditBoxesReports() {
  yield takeLatest(
    CreditBoxInvestorsActionType.CREDIT_BOX_PULL_ALL_REPORTS_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId } }) {
      yield call(pullAllCreditBoxesReports, companyId);
    }, noop)
  );
}

export function* handlePullCreditBoxReport() {
  yield takeLatest(
    CreditBoxInvestorsActionType.CREDIT_BOX_REFRESH_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId, reportType } }) {
      yield call(refreshCreditBoxReport, companyId, reportType);
    }, noop)
  );
}

export function* handleGetCreditBoxReports() {
  yield takeLatest(
    CreditBoxInvestorsActionType.CREDIT_BOX_REPORTS_HOOK_USED,
    catchError(function* ({ payload: { companyId } }) {
      yield call(getMultipleCreditBoxReports, companyId);
    }, noop)
  );
}

export function* handleDownloadCreditBoxReport() {
  yield trackActions([
    {
      actionType:
        CreditBoxInvestorsActionType.CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED,
      track: ({ payload }) => ({
        name: 'admin-matches-download-credit-box-button-clicked',
        payload,
      }),
    },
  ]);
  yield takeLatest(
    CreditBoxInvestorsActionType.CREDIT_BOX_DOWNLOAD_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId, reportId } }) {
      yield call(createCreditBoxReportDownloadLink, companyId, reportId);
    }, noop)
  );
}
