import React from "react";
import _pub4435Fe58 from "../../../../../../../design-system/src/Pill.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Frozen = React.memo(React.forwardRef(function Frozen(props, ref) {
  return React.createElement(_pub4435Fe58, {
    "class": "_8ec8bfd1 _b8ee71a8 _pub-b8ee71a8",
    "ref": ref,
    "bold": true,
  }, 
    "Frozen"
    
  )
}));
export { Frozen };


