import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useField, useForm } from '@hum/common/src/modules/form';
import { fundraisingGoalsFormSubmitted } from '@hum/icm-app/src/actions';
import { pickBy } from 'lodash';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { LastFundingRound } from '@hum/types';
import { useAnalyticsEvent } from '@hum/api';

export const LAST_STAGE_FUNDING = 'lastStageOfFunding';
export const DATE_OF_LAST_RAISE = 'dateOfLastRaise';
export const TOTAL_EQUITY_RAISED = 'totalEquityRaised';
export const LAST_VALUATION = 'lastValuation';

const isDate = (date: Date) => {
  return new Date(date).toString() !== 'Invalid Date';
};

export type FormValues = {
  [LAST_STAGE_FUNDING]: string;
  [DATE_OF_LAST_RAISE]?: string | number;
  [TOTAL_EQUITY_RAISED]?: string;
  [LAST_VALUATION]?: string;
};

export const validationSchema = Yup.object().shape({
  [LAST_STAGE_FUNDING]: Yup.string().required('Required'),
  [DATE_OF_LAST_RAISE]: Yup.string()
    .optional()
    .nullable(true)
    .default(null)
    .transform((_, val) => (val === val ? val.toString() : ''))
    .test({
      name: 'date',
      exclusive: true,
      message: 'Incorrect date',
      test: (value) => {
        if (value === null || value === '') return true;
        const textValue = value.toString();
        if (textValue.length < 5) return false;
        const year = textValue.substring(textValue.length - 4);
        const month = textValue.substring(0, textValue.length == 5 ? 1 : 2);
        const dateResult =
          month.length == 1
            ? new Date(`0${month}-01-${year}`)
            : new Date(`${month}-01-${year}`);

        if (!isDate(dateResult)) return false;
        const currentDate = new Date();
        if (dateResult > currentDate) return false;

        return true;
      },
    }),
  [LAST_VALUATION]: Yup.number()
    .positive('Must be positive value')
    .min(100000, 'Minimum amount is $100,000')
    .integer('Use whole numbers without notations like 1K, 1M, 1B')
    .nullable(true)
    .transform((_, val) => (val === val ? val : null)),
});

const INITIAL_VALUES: FormValues = {
  [LAST_STAGE_FUNDING]: '',
  [DATE_OF_LAST_RAISE]: '',
  [TOTAL_EQUITY_RAISED]: '',
  [LAST_VALUATION]: '',
};

export function useFundraisingHistory() {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const trackEvent = useAnalyticsEvent();

  useEffect(() => {
    trackEvent('company-visit-fundraising-history');
  }, []);

  const form = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (payload: FormValues) => {
      const { dateOfLastRaise, ...rest } = payload;
      let formattedDate = '';
      if (dateOfLastRaise) {
        formattedDate =
          dateOfLastRaise.toString().length == 5
            ? `0${dateOfLastRaise
                .toString()
                .slice(0, 1)}-01-${dateOfLastRaise.toString().slice(1)}`
            : `${dateOfLastRaise
                .toString()
                .slice(0, 2)}-01-${dateOfLastRaise.toString().slice(2)}`;
      }

      dispatch(
        fundraisingGoalsFormSubmitted({
          companyId: company.data!.id,
          payload: {
            application: pickBy({
              [LAST_STAGE_FUNDING]: rest[LAST_STAGE_FUNDING],
              [DATE_OF_LAST_RAISE]:
                formattedDate != ''
                  ? new Date(formattedDate).toISOString()
                  : '',
              [LAST_VALUATION]: rest[LAST_VALUATION],
              [TOTAL_EQUITY_RAISED]: rest[TOTAL_EQUITY_RAISED],
            }),
          },
        })
      );
    },
  });

  const lastStageOfFunding = useField(LAST_STAGE_FUNDING, 'select', form).input;
  const dateOfLastRaise = useField(DATE_OF_LAST_RAISE, 'text', form).input;
  const totalEquityRaised = useField(TOTAL_EQUITY_RAISED, 'select', form).input;
  const lastValuation = useField(LAST_VALUATION, 'text', form).input;

  const disableAdditionalFields =
    lastStageOfFunding.value === LastFundingRound.Bootstrapped;

  // Disable and reset fields if bootstrapped is selected
  useEffect(() => {
    if (disableAdditionalFields) {
      if (dateOfLastRaise) {
        form.setFieldValue(
          DATE_OF_LAST_RAISE,
          INITIAL_VALUES[DATE_OF_LAST_RAISE]
        );
      }
      if (totalEquityRaised) {
        form.setFieldValue(
          TOTAL_EQUITY_RAISED,
          INITIAL_VALUES[TOTAL_EQUITY_RAISED]
        );
      }
      if (lastValuation) {
        form.setFieldValue(LAST_VALUATION, INITIAL_VALUES[LAST_VALUATION]);
      }
    }
  }, [disableAdditionalFields]);

  return {
    form,
    fields: {
      lastStageOfFunding,
      dateOfLastRaise,
      totalEquityRaised,
      lastValuation,
    },
    disableAdditionalFields,
  };
}
