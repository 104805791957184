import React, { useState } from 'react';
import { Company } from '@hum/types';
import { BUSINESS_TYPES } from '@hum/icm-app/src/components/constants';
import {
  Button,
  DescriptionList,
  InfoSection,
  SectionCard,
  Text,
  TextType,
} from '@hum/ui-library';
import { money } from '@hum/icm-app/src/utils/format';
import { Modal } from '@hum/design-system';
import { Alert, Box, SelectOption } from '@hum/legacy-ui';
import { renderInvestmentTypes } from '../../../InvestorOverview/InvestmentProfile';
import { CompanySyndicationForm } from './UpdateModal';
import { SyndicationUnwantedInvestors } from './UnwantedInvestors';
import { CreditBoxInvestors } from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/CreditBoxInvestors';
export const CompanySyndication = ({ company }: { company: Company }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { application } = company;
  const openCompanySyndicationModal = () => {
    setModalOpen(true);
  };

  const onCloseCompanySyndicationModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <SectionCard className="flex items-center justify-between mb-10 rounded-md">
        <Text type={TextType.HEADING_L}>Deals</Text>
        <Button
          onClick={() =>
            window.location.assign(
              // eslint-disable-next-line
              `${process.env.V2_BASE_URL}/deals?companyId=${company.id}`
            )
          }
        >
          Create Deal
        </Button>
      </SectionCard>

      <Box gap="16px" alignEnd vertical>
        <InfoSection
          testId="admin-page:main-area:syndication:overview"
          title="Company Details"
          primaryAction={{
            text: 'Update Syndication Data',
            onClick: openCompanySyndicationModal,
          }}
        >
          <DescriptionList>
            <dt>
              <Text type={TextType.BODY_S} subdued>
                Looking for investment types
              </Text>
            </dt>
            <dd>
              {application.investmentTypes?.length
                ? renderInvestmentTypes(
                    (application.investmentTypes as unknown[]) as SelectOption[]
                  )
                : '–'}
            </dd>

            <dt>
              <Text type={TextType.BODY_S} subdued>
                Looking for investment amount
              </Text>
            </dt>
            <dd>
              <Text type={TextType.BODY_S}>
                {money(application.investmentAmount || 0)}
              </Text>
            </dd>

            <dt>
              <Text type={TextType.BODY_S} subdued>
                Business type for Syndication
              </Text>
            </dt>
            <dd data-testid="admin-page:main-area:syndication:overview:business-type">
              <Text type={TextType.BODY_S}>
                {(company.businessTypeForSyndication &&
                  BUSINESS_TYPES[company.businessTypeForSyndication]) ||
                  '–'}
              </Text>
            </dd>

            <dt>
              <Text type={TextType.BODY_S} subdued>
                Yearly Revenue for Syndication
              </Text>
            </dt>
            <dd data-testid="admin-page:main-area:syndication:overview:yearly-revenue">
              <Text type={TextType.BODY_S}>
                {money(application.yearlyRevenueForSyndication) || '–'}
              </Text>
            </dd>
          </DescriptionList>
          <br />
          {!application.investmentTypes?.length && (
            <Alert
              title="Investment types, investment ranges are required to continue."
              notice
            />
          )}
        </InfoSection>
      </Box>
      {modalOpen && (
        <Modal
          onClose={onCloseCompanySyndicationModal}
          testId="admin-page:main-area:syndication:company-information-modal"
          header="Syndication Data"
          veryWide
          scrollable
          fullHeight
        >
          <CompanySyndicationForm
            onClose={onCloseCompanySyndicationModal}
            company={company}
          />
        </Modal>
      )}
      <SyndicationUnwantedInvestors company={company} />
      <CreditBoxInvestors companyId={company.id} />
    </div>
  );
};
