import React, { useMemo } from 'react';

import * as Yup from 'yup';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { SyndicationMatchStatus } from '@hum/icm-app/src/state';
import { useField, useForm } from '@hum/common/src/modules/form';
import { Box, SelectInputField, Fieldset } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { UpdateMatchStatusPayload } from '@hum/icm-app/src/backend/api/syndication';

const transformValues = (status: string) => ({
  status: status,
});

// prettier-ignore
const validationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
})

declare type Props = {
  syndicationInvestorCompanyId: number;
  companyId: number;
  matchId: number;
  status: string;
  closeModal: () => void;
  companyIsCompany: boolean;
};

const matchStatusOptions = [
  {
    label: 'Matched',
    value: SyndicationMatchStatus.Matched,
  },
  {
    label: 'NoMatched',
    value: SyndicationMatchStatus.NoMatched,
  },
  {
    label: 'TermSheetSigned',
    value: SyndicationMatchStatus.TermSheetSigned,
  },
  {
    label: 'Closed',
    value: SyndicationMatchStatus.Closed,
  },
  {
    label: 'Failed',
    value: SyndicationMatchStatus.Failed,
  },
];

export const UpdateMatchStatus: React.FC<Props> = ({
  syndicationInvestorCompanyId,
  companyId,
  matchId,
  status,
  closeModal,
  companyIsCompany,
}) => {
  const api = useAPI();
  const { dispatch } = useAppStore();
  const initialValues = useMemo(() => transformValues(status), [status]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async (payload: UpdateMatchStatusPayload, { resetForm }) => {
      await api.syndication.updateMatchStatus(dispatch)(
        companyIsCompany ? companyId : syndicationInvestorCompanyId,
        matchId,
        payload
      );
      closeModal();
      resetForm();
    },
  });

  const closeForm = () => {
    closeModal();
    form.resetForm();
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Fieldset>
        <SelectInputField
          data-testid="syndication-panel:update-match-status:status-select"
          aria-label="Status"
          options={matchStatusOptions}
          value={status}
          label="Status"
          disabled={!matchStatusOptions.length}
          loading={!matchStatusOptions.length}
          {...useField('status', 'select', form).input}
        />
      </Fieldset>

      <Box justifySpaceBetween>
        <Button
          type={ButtonType.SECONDARY}
          onClick={closeForm}
          disabled={form.isSubmitting}
        >
          Cancel
        </Button>

        <Button submit disabled={form.isSubmitting} loading={form.isSubmitting}>
          Update
        </Button>
      </Box>
    </form>
  );
};
