import { useEffect } from 'react';
import { useQuery } from '@hum/common/src/hooks/useUrlQuery';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Routes } from '@hum/icm-app/src/state/routes';
import { userVerifyEmailVisited } from '@hum/icm-app/src/actions';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useApiMutation, verifyEmail } from '@hum/api';

export const VerifyEmail = () => {
  const flags = useFlags();
  const { mutate: verifyUserEmail } = useApiMutation(verifyEmail);
  const dispatch = useDispatch();
  const history = useHistory();
  const { token = '' }: { token?: string } = useQuery();

  useEffect(() => {
    const verifyToken = async () => {
      //if theres no token there's no point of having the user here
      if (token === '') {
        history.push(Routes.ROOT);
      }
      if (token) {
        flags.enabled('use-react-query')
          ? verifyUserEmail({ token })
          : dispatch(userVerifyEmailVisited({ token }));
      }
    };
    verifyToken();
  }, []);

  return null;
};
