import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pubFc2Ceb2, {TabNavigationContainer as _pubFc2Ceb2_TabNavigationContainer,TabNavigationItems as _pubFc2Ceb2_TabNavigationItems,TabNavigationItem as _pubFc2Ceb2_TabNavigationItem} from "../../../../../../design-system/src/TabNavigation.pc";
import _pub622F8858, {MenuContainer as _pub622F8858_MenuContainer,MenuItem as _pub622F8858_MenuItem} from "../../../../../../design-system/src/Menu.pc";
import _pub4435Fe58 from "../../../../../../design-system/src/Pill.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var AnalyticsTabLabel = React.memo(React.forwardRef(function AnalyticsTabLabel(props, ref) {
  return React.createElement("div", {
    "className": "_98b76238 _de749ff _pub-de749ff _pub-a97d53f3" + (props["visible"] ? " " + "_de749ff_visible _pub-de749ff_visible _pub-a97d53f3_visible visible" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "onMouseEnter": props["onMouseEnter"],
    "onMouseLeave": props["onMouseLeave"],
  }, 
    React.createElement("div", {
      "className": "_840e6c12 _de749ff _pub-de749ff _pub-a97d53f3",
    }, 
      props["children"],
      React.createElement(_pub622F8858_MenuContainer, {
        "class": "_cdd9184 _de749ff _pub-de749ff _pub-a97d53f3",
      }, 
        React.createElement(_pub622F8858, {
          "class": "_9fcd8afc _de749ff _pub-de749ff _pub-a97d53f3",
          "absolute": true,
        }, 
          props["menuItems"]
        )
      )
    ),
    React.createElement("div", {
      "className": "_1d073da8 _de749ff _pub-de749ff _pub-a97d53f3",
    }, null)
  )
}));
export { AnalyticsTabLabel };

var MenuItem = React.memo(React.forwardRef(function MenuItem(props, ref) {
  return React.createElement(_pub622F8858_MenuItem, {
    "class": "_7f0f4f3f _de749ff _pub-de749ff _pub-a97d53f3",
    "ref": ref,
    "selected": props["selected"],
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { MenuItem };


