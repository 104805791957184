import React from "react";
import _pubA97D53F3 from "../../../../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../../design-system/src/utils.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var StyledSyndicationMatch = React.memo(React.forwardRef(function StyledSyndicationMatch(props, ref) {
  return React.createElement("section", {
    "className": "_d3fb5544 _faf79d42 _pub-faf79d42 _pub-a97d53f3" + " " + "_faf79d42_SyndicationMatch _pub-faf79d42_SyndicationMatch _pub-a97d53f3_SyndicationMatch SyndicationMatch",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { StyledSyndicationMatch };

var SyndicationMatchHead = React.memo(React.forwardRef(function SyndicationMatchHead(props, ref) {
  return React.createElement("section", {
    "className": "_3df53468 _faf79d42 _pub-faf79d42 _pub-a97d53f3" + " " + "_faf79d42_SyndicationMatchHead _pub-faf79d42_SyndicationMatchHead _pub-a97d53f3_SyndicationMatchHead SyndicationMatchHead",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { SyndicationMatchHead };

var SyndicationTitle = React.memo(React.forwardRef(function SyndicationTitle(props, ref) {
  return React.createElement("h4", {
    "className": "_da4d196f _faf79d42 _pub-faf79d42 _pub-a97d53f3" + " " + "_faf79d42_SyndicationTitle _pub-faf79d42_SyndicationTitle _pub-a97d53f3_SyndicationTitle SyndicationTitle",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { SyndicationTitle };


