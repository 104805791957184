import { useEffect } from 'react';

import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { pickBy } from 'lodash';
import { useField, useForm } from '@hum/common/src/modules/form';
import { basicBusinessFormSubmitted } from '@hum/icm-app/src/actions';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useAnalyticsEvent } from '@hum/api';
export const COUNTRY = 'country';
export const STATE = 'state';
export const YEAR_FOUNDED = 'yearFounded';
export const SELF_REPORTED_INDUSTRY = 'industry';
export const REVENUE_MODEL = 'revenueModel';

export type FormValues = {
  [COUNTRY]: string;
  [STATE]: string;
  [YEAR_FOUNDED]: string;
  [SELF_REPORTED_INDUSTRY]: string;
  [REVENUE_MODEL]: string;
};

export const MIN_YEAR_FOUNDED = 578;
export const MAX_YEAR_FOUNDED = new Date().getFullYear();

export const validationSchema = Yup.object().shape({
  [STATE]: Yup.string().required('Required'),
  [YEAR_FOUNDED]: Yup.number()
    .typeError('Numbers only')
    .min(
      MIN_YEAR_FOUNDED,
      `Year founded cannot be lower than ${MIN_YEAR_FOUNDED}`
    )
    .max(
      MAX_YEAR_FOUNDED,
      `Year founded cannot be higher than ${MAX_YEAR_FOUNDED}`
    )
    .required('Required'),
  [SELF_REPORTED_INDUSTRY]: Yup.string().required('Required'),
  [REVENUE_MODEL]: Yup.string().required('Required'),
});

const INITIAL_VALUES = {
  [COUNTRY]: '',
  [STATE]: '',
  [YEAR_FOUNDED]: '',
  [SELF_REPORTED_INDUSTRY]: '',
  [REVENUE_MODEL]: '',
};

export function useAboutYourBusiness() {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const trackEvent = useAnalyticsEvent();
  const dynamicValidationCountrySchema = () => {
    return validationSchema.shape({
      [COUNTRY]: Yup.string().required('Required'),
      [STATE]: Yup.string().notRequired(),
    });
  };

  useEffect(() => {
    trackEvent('company-visit-tell-us-about-your-business');
  }, []);

  const form = useForm({
    initialValues: INITIAL_VALUES,
    validationSchema: dynamicValidationCountrySchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (payload) => {
      const { yearFounded, ...rest } = payload;
      dispatch(
        basicBusinessFormSubmitted({
          companyId: company.data!.id,
          payload: {
            ...rest,
            application: pickBy({
              revenueModel: rest[REVENUE_MODEL],
              selfReportedIndustry: rest[SELF_REPORTED_INDUSTRY],
              yearFounded: Number(yearFounded),
            }),
          },
        })
      );
    },
  });

  const country = useField(COUNTRY, 'select', form).input;
  const state = useField(STATE, 'select', form).input;
  const selfReportedIndustry = useField(SELF_REPORTED_INDUSTRY, 'select', form)
    .input;
  const yearFounded = useField(YEAR_FOUNDED, 'text', form).input;
  const revenueModel = useField(REVENUE_MODEL, 'select', form).input;

  return {
    form,
    fields: {
      country,
      state,
      selfReportedIndustry,
      yearFounded,
      revenueModel,
    },
  };
}
