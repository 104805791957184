import React from 'react';

import { InfoSection, Empty } from '@hum/ui-library';
import { useSyndicationMatches } from '@hum/icm-app/src/hooks/syndication';
import { Match } from '../Match';

export const InvestorSyndication = ({
  companyIsCompany,
}: {
  companyIsCompany: boolean;
}) => {
  const { loaded, data: matches } = useSyndicationMatches();

  return (
    <InfoSection title="Introductions">
      {loaded && matches?.length === 0 && (
        <Empty message="There are no companies matched with this investor." />
      )}
      {matches?.map((match) => (
        <Match
          key={match.id}
          match={match}
          companyIsCompany={companyIsCompany}
        />
      ))}
    </InfoSection>
  );
};
